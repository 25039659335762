<template>
  <div>
        <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
        {{alertError}}
      </v-alert>
      <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
        {{alertSuccess}}
      </v-alert>
  
    <v-card color="cyan lighten-5" rounded="0" flat min-height="100%">
      <v-row>
        <v-col cols="1" class="pb-0">
          <v-btn to="/schoolTeacher/classroom" text class="mr-1" x-large>
            <v-icon>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </v-col>
        <v-col cols="9" class="pb-0">
          <v-card-title class="justify-center pa-1">
            <h2 class="font-weight-bold  basil--text ">
              {{classroom.Name}}
            </h2>
            
          </v-card-title>
        </v-col>
        <!-- <v-col>
          <div class="text-center pa-1">
            <v-btn rounded color="blue-grey lighten-2" @click="generateCode" :loading="loadCode" :disabled="loadCode" v-if="CodeAccess==''">
              Generate Code
            </v-btn>
            <div  v-else >
              Code : <v-chip color="cyan accent-4" label>{{ CodeAccess }}</v-chip>
            </div>
          </div>
        </v-col> -->
      </v-row>
  
      <v-tabs v-model="tab" background-color="cyan lighten-5" color="indigo darken-4" @change="cambioTab" centered slider-color="indigo darken-4" slider-size="3">
        <v-tab>CLASS</v-tab>
        <v-tab>GAME PROGRESS</v-tab>
        <v-tab v-if="tempTokenClever == ''">GENERATE QR</v-tab>
        <v-tab>PROGRESS CHECK</v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card color="basil" flat>
            <v-container fluid>
              <v-row  v-if="user.Multiplayer">
                <v-col class="col-md-4 col-sm-5">
                  <v-card elevation="2" class="px-4" min-height="450px">
                    <!-- <v-card-title class="text-center justify-center text-h4">Classroom</v-card-title> -->
                    <v-row>
                      <v-col cols="11" sm="11" md="11" lg="10" xl="10" class="text-h5 pl-4 my-2">Classroom</v-col>
                      <!-- <v-col cols="1" sm="1" md="1" lg="1" xl="1" class="">
                        <v-btn icon color="blue-grey"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
                      </v-col> -->
                    </v-row>
                    
                  <v-card>
                    <v-toolbar flat color="rgba(17,97,94,1)" dense dark>
                      <v-toolbar-title>Students</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip top v-if="tempTokenClever == ''">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn rounded color="success" v-bind="attrs" v-on="on" @click="openModalImportStudent">
                            <v-icon>mdi-plus</v-icon>
                            Add
                          </v-btn>
                        </template>
                        <span>Add Student to class</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text class="px-1">
                      <div>
                        <Container
                            group-name="column"
                            :get-child-payload="(itemIndex) => getChildPayloadMain( itemIndex)"
                            @drop="onDrop(0,'MainGroup', $event)"
                        >
                            <Draggable v-for="item in mainGroup" :key="item.id">
                            <div class="draggable-item" v-if="item.group == ''">
                              <v-row class="mx-0" align="center">
                                <v-col cols="1" class="px-0">
                                  <v-menu offset-x>
                                    <template v-slot:activator="{ on: menu, attrs }">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                          <v-btn
                                            icon color="black"
                                            v-bind="attrs"
                                            v-on="{ ...tooltip, ...menu }"
                                            
                                          >
                                            <v-icon dark>mdi-set-merge</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Assign group</span>
                                      </v-tooltip>
                                    </template>
                                    <v-list dense>
                                      <v-list-item
                                        v-for="(group, index) in groups" :key="index" link>
                                        <v-list-item-title @click="ClickShowOptionsGroups(group,item)">{{ group.name }}</v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                  <!-- <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon color="black" v-bind="attrs" v-on="on" @click="ClickShowOptionsGroups(item)">
                                        <v-icon dark>mdi-set-merge</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Assign group</span>
                                  </v-tooltip> -->
                                </v-col>
                                <v-col cols="10" class="px-0">{{ item.name }} {{ item.lastName}}</v-col>
                                <v-col cols="1" class="px-0">
                                  <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon color="black" v-bind="attrs" v-on="on" @click="ClickButtonDeleteStudent(item)">
                                        <v-icon dark>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remove student</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                                
                                  
                            </div>
  
                            </Draggable>
                        </Container>
                      </div>
                    </v-card-text>
                  </v-card>
                  <div class="text-center pa-1 text-h6" v-if="user.TypeLogin!='email'">
                      <v-btn rounded color="blue-grey lighten-2" @click="generateCode" :loading="loadCode" :disabled="loadCode" v-if="CodeAccess==''">
                        Generate the Code to add Students
                      </v-btn>
                      <div v-else >
                        Student´s Code : <v-chip color="cyan accent-4" label>{{ CodeAccess }}</v-chip>
                      </div>
                      
                  </div>
                  </v-card>
                </v-col>
                <v-col class="col-md-8 col-sm-7">
                  <v-card min-height="100%">
                    <v-card-title class="text-center justify-center text-h5">Multiplayer Groups  
                      <!-- <div v-if="user.Multiplayer">
                        <div v-if="esTiempoActivo"><v-chip class="ma-2">{{ TiempoTemporizador }}</v-chip></div>
                        <v-btn class="ma-2" color="teal lighten-1" dark @click="ModalJuegos()" v-else>
                          <v-icon>mdi-google-controller</v-icon>
                          Juegos 
                        </v-btn>
                      </div> -->
                    </v-card-title>
                    <v-card-text>
  
                      <v-row>
                        <v-col cols="4" md="4" sm="6" v-for="(items, index) in groups" :key="index">
                          <v-card color="grey lighten-4" elevation="2" min-height="250px">
                            <v-toolbar color="rgba(17,97,94,1)" flat dense dark>
                              <v-checkbox :disabled="groupsDragDrop[index].length==0" v-model="GroupSelected" color="success" :value="index" hide-details v-if="items.IdRoom== ''" ></v-checkbox>
                              <v-toolbar-title>{{ items.name }}</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-toolbar-title v-if="items.IdRoom != ''">In progress</v-toolbar-title>
                              <!-- <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on" @click="EditGroup(items)">
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>Edit Group</span>
                              </v-tooltip> -->
                              <v-tooltip top v-if="items.IdRoom!= ''">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on" @click="DeleteGameProgress(items)">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remove Game</span>
                              </v-tooltip>
                            </v-toolbar>
                            <div v-if="groupsDragDrop[index].length == 0" class="text-subtitle-1 grey--text texto-fondo">
                                  Drop students
                            </div>  
                            <Container
                            style="min-height: 190px;"
                            :data-index="index"
                            group-name="column"
                            :get-child-payload="(itemIndex) => getChildPayload(index, itemIndex)"
                            @drop="onDrop(index, 'groupsDragDrop', $event)"
                            >
                                
                                <Draggable v-for="item in groupsDragDrop[index]" :key="item.id" :drag-not-allowed="(items.IdRoom!=''?true:false)">
                                <div class="draggable-item-no" v-if="(typeof(item.LastLoginPlatform)!=='undefined'?item.LastLoginPlatform:'') && item.LastLoginPlatform.toLowerCase()=='webgl'" title="Este estudiante esta conectado desde un navegador.">
                                  {{ item.name }} {{ item.lastName}} <span class="red--text">(from Web)</span>
                                </div>
                                <div class="draggable-item" v-else>
                                  {{ item.name }} {{ item.lastName}}
                                </div>
                                </Draggable>
                                
                            </Container>
                            <!-- <div v-if="groupsDragDrop[index].length == 0" class="text-subtitle-1 text-center grey--text">
                              Drop students
                            </div> -->
                          </v-card>
                        
                        </v-col>
                        <!-- <v-col cols="4" md="4" sm="6">
                          <v-card @click="OpenDialogNewGroup" min-height="110">
                            <v-col class="text-center">
                              <v-icon size="55" class="ma-md-4 ma-sm-0">mdi-plus</v-icon>
                              <p class="text-h5">New Group</p>
                            </v-col>
                          </v-card>
                        </v-col> -->
                    </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col cols="7">
                          <v-btn class="ma-2" color="teal lighten-2" dark small outlined @click="AddGroupDirectly()">
                            <v-icon>mdi-plus</v-icon>
                            Add 
                          </v-btn>
                          <v-btn class="ma-2" color="teal lighten-2" dark small outlined @click="ConfirmRandom()">
                            <v-icon>mdi-shuffle-variant</v-icon>
                            Random 
                          </v-btn>
                          <v-btn class="ma-2" color="deep-orange lighten-2" small outlined @click="RemoveStudentsGroups()">
                            <v-icon>mdi-broom</v-icon>
                            Clean 
                          </v-btn>
                        </v-col>
                        <!-- <v-col cols="2">
                          <v-btn class="ma-2" color="teal lighten-2" dark small outlined @click="RandomStudents()">
                            <v-icon>mdi-shuffle-variant</v-icon>
                            Random Groups 
                          </v-btn>
                        </v-col>
                        <v-col cols="2">
                          <v-btn class="ma-2" color="deep-orange lighten-2" small outlined @click="RemoveStudentsGroups()">
                            <v-icon>mdi-broom</v-icon>
                            Clean Groups 
                          </v-btn>
                        </v-col> -->
                        <v-col cols="3"  offset="1">
                          <v-btn class="ma-2" color="teal lighten-1" dark @click="ModalJuegos()">
                            <v-icon>mdi-google-controller</v-icon>
                            Schedule Games 
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else justify="center">
                <v-col class="col-md-8 col-sm-10">
                  <v-card class="pa-2" >
                     <v-card-title class="text-center justify-center text-h5">Classroom 
                        <v-btn class="mx-3" rounded color="success" @click="openModalImportStudent">
                            <v-icon>mdi-plus</v-icon>
                            Add
                          </v-btn>
                     </v-card-title>
                     <v-row>
                      <v-col cols="6" class="py-1" v-for="item in mainGroup" :key="item.id">
                          <div class="draggable-item" >
                              <v-row class="mx-0" align="center">
                                <v-col cols="10" class="pr-0">{{ item.name }} {{ item.lastName}}</v-col>
                                <v-col cols="2" class="px-1">
                                  <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon color="black" v-bind="attrs" v-on="on" @click="ClickButtonDeleteStudent(item)">
                                        <v-icon dark>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remove student</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </div>
                      </v-col>
                     </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="3" md="3" lg="3" xl="3">
                <v-subheader>
                  Select Student
                </v-subheader>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="3" xl="3">
                <v-select
                  v-model="SelectStudentProgress"
                  :items="mainGroup"
                  outlined
                  menu-props="auto"
                  label="Select"
                  hide-details
                  prepend-icon="mdi-account-arrow-right-outline"
                  single-line
                  :item-text="item => item.name +' '+ item.lastName"
                  item-value="id"
                  @change="SelectStudentName"
                  :disabled = "(SelectStudentProgress == -1)"
                ></v-select>
              </v-col>
              <!-- <v-col>
                <v-btn
                  class="ma-2"
                  color="error"
                  @click="CancelChangeLevelClass"
                  v-if="SelectStudentProgress == -1"
                >
                  Cancel 
                  <v-icon right dark>mdi-close</v-icon>
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="secondary"
                  @click="ChangeLevelAllClass"
                  v-else
                >
                  All Class 
                </v-btn>
                
              </v-col> -->
            </v-row>
            <v-card v-if="SelectStudentProgress != ''">
              <v-row>
                <v-col cols="5">
                  <p class="text-center text-h6 mb-0">Misiones</p>
                </v-col>
                <v-col cols="5">
                  <p class="text-center text-h6 mb-0">Etapa</p>
                </v-col>
                <v-col cols="2">
                  <p class="text-center text-h6 mb-0">Avance</p>
                </v-col>
              </v-row>
              <!-- <v-row v-if="studentGameAlready"> -->
              <v-row>
                <v-col cols="10">
                  <template v-for="(element, index) in missionsNames">
                  <v-row class="bloqueMision" :key="index">
                    <v-col cols="4" class="col-sm-3 py-1">
                      <v-img  
                        class="ma-auto borderImage"
                        max-height="150"
                        max-width="250"
                        :src="require('../../../assets/admin/'+element.img)"
                        content-class="numeroMision"
                      >{{index+1}}</v-img>
                    </v-col>
                    <v-col cols="3" class="col-sm-4 py-0">
                      <p class="tituloMision" v-bind:class="[index == 3 ? 'tituloGrande' : '']">{{ element.name}}</p>
                    </v-col>
                    <v-col cols="5" class="py-1">
                      <div class="listaMision">
                        <!-- <p class="selected">1. o-a-i-u-e</p> -->
                        <template v-for="(element, index) in element.misiones">
                          <p :key="index">{{element}}</p>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                  </template>
                </v-col>
                <v-col cols="2">
                  <div class="range-slider">
                    <input @change="CambioSliderValue" @input="ChangeColor" v-model="avanceJuego" class="input-range" type="range" step="1" value="1" min="1" max="35" id="valorRange">
                    <span class="range-value"></span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="tempTokenClever == ''">
          <v-card color="basil" flat>
            <v-container>
              <v-card-text>
                <v-data-table :headers="headerTableQR" :items="listExportQR" class="elevation-1" v-model="selectedExport" show-select id="tablaExportar">
                  <!-- <template v-slot:[`header.data-table-select`]="{ props, on }">
                    <v-simple-checkbox
                            :value="props.value || props.indeterminate"
                            v-on="on"
                            :indeterminate="props.indeterminate"
                            color="success"
                            class="blue-grey lighten-5"
                          />
                    </template> -->
                  <!-- <template v-slot:[`item.export`]="{ item }">
                    <v-simple-checkbox v-model="item.export" :ripple="false" color="success"></v-simple-checkbox>
                  </template> -->
                </v-data-table>
                <v-btn color="success" class="mb-10" dark absolute bottom right fab @click="ExportQR">
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-tabs v-model="TabTest" background-color="teal lighten-3" centered dark icons-and-text @change="cambioTabTest">
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                Manage Progress Check
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-tab>
              <v-tab href="#tab-2">
                Progress Check Report
                <v-icon>mdi-chart-box-outline</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="TabTest">
              <v-tab-item value="tab-1">
                <v-card class="ma-5">
                    <v-data-table dense hide-default-footer disable-pagination :headers="headerTableListTest" :items="ListManageTest" class="elevation-1">
                      <template v-slot:item.months="{ item }">
                        <v-chip dark small v-for="(i,index9) in item.months" :color="(i.IsComplete) ?'success': 'blue-grey'" :outlined="(!i.IsComplete)" :key="index9">{{i.ShortName}} {{ i.anio}}</v-chip>
                      </template>
                      <template v-slot:item.actions="{ item }">
                       <v-btn color="blue-grey" small class="ma-2 white--text" @click="GenerateTestByStudent(item)" :disabled="item.months==0">
                          Report
                          <v-icon right dark>mdi-chart-box-outline</v-icon>
                        </v-btn>
                        <v-btn color="brown lighten-1" small class="ma-2 white--text" @click="RegenerateTestOneStudent(item)" v-if="item.current">
                          Restart
                          <v-icon right dark>mdi-reload</v-icon>
                        </v-btn>
                        <v-btn color="light-green darken-3" small class="ma-2 white--text" @click="RegenerateTestOneStudent(item)" v-else>
                          Schedule
                          <v-icon right dark>mdi-account-plus</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                </v-card>
                <v-row justify="space-between">
                  <v-col cols="4" class="ma-3">
                    <p>
                      <v-chip dark color="success">month</v-chip> : Completed Progress Check
                    </p>
                    <p>
                      <v-chip dark color="blue-grey" outlined>month</v-chip> : Pending Progress Check
                    </p>
                    <v-btn class="ma-2" color="error" outlined dark x-small :disabled="!ExistTestCurrentMonth" @click="dialogConfirmDeleteTest= true">
                      <v-icon>mdi-delete</v-icon>
                        Remove this month's Progress Check 
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-btn class="ma-2 white--text" color="teal lighten-1" @click="ReporteExamen()">
                      <v-icon>mdi-account-multiple-check</v-icon>
                      Schedule Progress Check 
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card flat class="ma-5">
                  <v-row>
                    <v-col cols="3" v-if="ItemsDatesTests.length > 0">
                      <v-select
                        :items="ItemsDatesTests"
                        label="Select Date"
                        outlined
                        item-text="fecha"
                        item-value="value"
                        @change="WriteTableTest"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1" offset="1">
                      <v-subheader>
                        Student
                      </v-subheader>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="TestReportStudentSelected"
                        :items="OptionsStudentsReport"
                        item-text="name"
                        item-value="id"
                        label="Select"
                        return-object
                        dense
                        outlined
                        @change="GetDataStudentMonth"
                      ></v-select>
                    </v-col>
                    <v-col cols="1">
                      <v-subheader>
                        Month
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="TestReportMonthSelected"
                        :items="OptionsMonthsReport"
                        label="Select"
                        return-object
                        dense
                        outlined
                        @change="GetDataStudentMonth"
                        ref="monthsReport"
                      ></v-select>
                    </v-col>
                    <v-col cols="2" v-if="ListFinalStudents.length > 0">
                        <v-btn class="ma-2" color="teal lighten-1" dark outlined @click="PrintReport()">
                          <v-icon>mdi-file-download-outline</v-icon>
                          Print 
                        </v-btn>
                    </v-col>
                  </v-row>
                  <p class="text-h4 text-center" v-if="ListFinalStudents.length > 0">Progress Check Report</p>
  
                  <!-- <div v-if="ListFinalStudents.length == 1"> -->
                  <div v-show="false">
                    <v-card color="grey lighten-4" flat  tile dark>
                      <v-toolbar color="rgba(17,97,94,1)" dense>
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>
                        <v-toolbar-title>Results</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-card elevation="2" color="blue darken-4">
                              <v-card-title class="justify-center">2 Progress Check Completed</v-card-title>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card elevation="2" color="blue darken-3">
                              <v-card-title class="justify-center">2 Progress Check Completed</v-card-title>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card elevation="2" color="blue darken-2">
                              <v-card-title class="justify-center">70% correct answers</v-card-title>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card elevation="2" color="blue darken-1">
                              <v-card-title class="justify-center">20% improvement since last test</v-card-title>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card color="grey lighten-4" flat  tile dark>
                      <v-toolbar color="rgba(17,97,94,1)" dense>
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>
                        <v-toolbar-title>Graphs</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col>
                              
                              <bar :chart-data="barCharData" :options="barChartOptions" :height="200"/>
                          </v-col>
                          <v-col>
                            <pie :chart-data="CharAciertos" :options="CharAciertosOptions" :height="200"/>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
               
                  <table class="tablaReporteTest" id="tablaPrueba" v-if="ListFinalStudents.length > 0" >
                    <colgroup>
                      <col width=200/>
                      <col class="column-mision1" span="2" width=70/>
                      <col class="column-mision2" span="2" width=70/>
                      <col class="column-mision3" span="2" width=70/>
                      <col class="column-mision4" span="2" width=70/>
                      <col class="column-mision5" span="2" width=70/>
                      <col class="column-mision6" span="2" width=70/>
                      <col class="column-mision7" span="2" width=70/>
                      <col class="column-mision6" span="2" width=70/>
                      <!-- <col class="column-final" width=70/> -->
                    </colgroup>
                    <thead>
                      <tr>
                        <th rowspan="2">{{TextoCabeceraTabla}}</th>
                        <th colspan="2">Sonidos (20)</th>
                        <th colspan="2">Sílabas directas (20)</th>
                        <th colspan="2">Palabras 2 sílabas (20)</th>
                        <th colspan="2">Sílabas con extensión (20)</th>
                        <th colspan="2">Sílabas inversas (20)</th>
                        <th colspan="2">Palabras con inicio (20)</th>
                        <th colspan="2">Palabras 3 sílabas (10)</th>
                        <th colspan="2">Sílabas trabadas(10)</th>
                        <!-- <th rowspan="2">Puntaje Final</th> -->
                      </tr>
                      <tr>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                        <th><v-icon large color="green darken-2">mdi-check</v-icon></th>
                        <th><v-icon large color="red darken-2">mdi-close</v-icon></th>
                      </tr>
                    </thead>
                    <tbody id="TablaReportTest" >
                      <tr v-for="(student,index) in ListFinalStudents" :key="index" >
                        <td> {{student.name}} {{ student.lastName}}</td>
                        <template v-for="(test,index2) in student.StudentTest" >
                          <td >
                            <v-chip class='margin-test-chip' color='green' text-color='white'><strong>{{test.buenas}}</strong></v-chip>
                          </td>
                          <td >
                            <v-chip class='margin-test-chip' color='red' text-color='white' ><strong>{{test.malas}}</strong></v-chip>
                          </td>
                        </template>
                        <template v-for="(i,index3) in 8" >
                          <td v-if="student.StudentTest.length-i<0"></td>
                          <td v-if="student.StudentTest.length-i<0"></td>
                        </template>
                        <!-- <td v-for="i in (student.StudentTest.length)"></td> -->
                      </tr>
                    </tbody>
                  </table>
                  <div id="tablaImpresionTest" style="width: 810px;position:absolute;z-index:-1; font-size: 8px;">
                    <p style="text-align: center; font-size: 15px; font-weight: 500;">Progress Check Report</p>
                    <table class="tablaReporteTest"  >
                      <thead>
                        <tr>
                          <th rowspan="2" >{{TextoCabeceraTabla}}</th>
                          <th colspan="2" class="column-mision1">Sonidos (20)</th>
                          <th colspan="2" class="column-mision2" >Sílabas directas (20)</th>
                          <th colspan="2" class="column-mision3" >Palabras 2 sílabas (20)</th>
                          <th colspan="2" class="column-mision4" >Sílabas con extensión (20)</th>
                          <th colspan="2" class="column-mision5" >Sílabas inversas (20)</th>
                          <th colspan="2" class="column-mision6" >Palabras con inicio (20)</th>
                          <th colspan="2" class="column-mision7" >Palabras 3 sílabas (10)</th>
                          <th colspan="2" class="column-mision6" >Sílabas trabadas(10)</th>
                          <!-- <th rowspan="2">Puntaje Final</th> -->
                        </tr>
                      </thead>
                      <tbody id="TablaReportTest">
                        <tr v-for="(student,index) in ListFinalStudents" :key="index" >
                          <td > {{student.name}}</td>
                          <template v-for="(test,index2) in student.StudentTest" >
                            <td :class="'column-mision'+(index2+1)">
                              <v-chip small class='margin-test-chip-print' style="background-color: rgb(76 175 80); color:white;"><strong>{{test.buenas}}</strong></v-chip>
                            </td>
                            <td :class="'column-mision'+(index2+1)">
                              <v-chip small class='margin-test-chip-print' style="background-color: #f44336; color: white;" ><strong>{{test.malas}}</strong></v-chip>
                            </td>
                          </template>
                          <template v-for="(i,index3) in 8" >
                            <td v-if="student.StudentTest.length-i<0"></td>
                            <td v-if="student.StudentTest.length-i<0"></td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div id="tablaImpresionTestIMP" style="width: 760px;position:absolute;z-index:-1; font-size: 8px; margin-top: 15px !important;">
                    <p style="text-align: center; font-size: 20px; font-weight: bold;">Progress Check Report</p>
                    <p style="text-align: left; font-size: 12px; margin-left: 15px !important;"><strong>School: </strong>{{user.School}}</p>
                    <p style="text-align: left; font-size: 12px; margin-left: 15px !important;"><strong>Grade: </strong>{{classroom.Grade}}</p>
                    <p style="text-align: left; font-size: 12px; margin-left: 15px !important;"><strong>Teacher: </strong>{{user.Name+' '+user.LastName}}</p>
                    <table class="tablaReporteTest" style="margin-left: 15px !important;">
                      <thead>
                        <tr>
                          <th rowspan="2" style="background: #E2E2E2; font-weight: bold; font-size: 12px;">{{TestReportStudentSelected.id == -1?TestReportMonthSelected:TestReportStudentSelected.name}}</th>
                          <th colspan="2" class="column-header">Sonidos (20)</th>
                          <th colspan="2" class="column-header" >Sílabas directas (20)</th>
                          <th colspan="2" class="column-header" >Palabras 2 sílabas (20)</th>
                          <th colspan="2" class="column-header" >Sílabas con extensión (20)</th>
                          <th colspan="2" class="column-header" >Sílabas inversas (20)</th>
                          <th colspan="2" class="column-header" >Palabras con inicio (20)</th>
                          <th colspan="2" class="column-header" >Palabras 3 sílabas (10)</th>
                          <th colspan="2" class="column-header" >Sílabas trabadas(10)</th>
                          <!-- <th rowspan="2">Puntaje Final</th> -->
                        </tr>
                      </thead>
                      <tbody id="TablaReportTest">
                        <tr v-for="(student,index) in ListFinalStudents" :key="index" >
                          <td class="row-main"> {{student.name}}</td>
                          <template v-for="(test,index2) in student.StudentTest" >
                            <td class="tReportTest" style="color:green;">
                              {{test.buenas}}
                            </td>
                            <td class="tReportTest" style="color:red;">
                              {{test.malas}}
                            </td>
                          </template>
                          <template v-for="(i,index3) in 8" >
                            <td v-if="student.StudentTest.length-i<0"></td>
                            <td v-if="student.StudentTest.length-i<0"></td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="ListFinalStudents.length > 0">
                  <p class="mt-3 mb-1"><v-icon color="green darken-2">mdi-check</v-icon> : correct answer</p>
                  <p><v-icon color="red darken-2">mdi-close</v-icon> : wrong answer</p>
                  <p>Note: </p>
                  <ul>
                    <li>When the student fails repeatedly, the exam will be terminated.</li>
                    <li>Schedule the Progress Check only on the day it is taken</li>
                  </ul>
                  </div>
  
  
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-container>
      
        <div style="display:none;"> 
          <v-row id="qr-code">
            <v-col class="col-4" style="text-align: center;" v-for="(student,index) in listCanvasQr" :key="index" >
              <qrcode-vue :value="student.qr" :size="size" level="H"></qrcode-vue>
              <h5> {{ student.name }}</h5>
              <h5> {{ student.email }}</h5>
            </v-col>
          </v-row>
        </div>
      <v-dialog scrollable v-model="dialogTableTest" max-width="400px" persistent>
        <v-card style="height: 100%">
          <v-toolbar flat color="cyan lighten-4" dense>
            <p class="mb-0 text-h6 mx-auto">Select your Students</p> 
          </v-toolbar>
          <v-row justify="center" class="ma-5" style="height: 100%">
            <v-data-table dense hide-default-footer disable-pagination :headers="headerTableTest" :items="listTest" class="elevation-1" v-model="selectedTest" show-select id="tablaExportar">
            </v-data-table>
          </v-row>
          <div class="text-center py-3">
            <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="dialogTableTest = false">Cancel</v-btn>
            <v-btn large color="success" outlined type="submit" class="ml-2" @click="GenerateTest" v-if="IsOptionGenerateTest">Generate Test<v-icon>mdi-check</v-icon></v-btn>
            <v-btn large color="success" outlined type="submit" class="ml-2" @click="GenerateReport" v-if="IsOptionReportTest">List Report<v-icon>mdi-check</v-icon></v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogNewGroup" max-width="1000px">
        <v-card>
          <v-form ref="form" v-model="valid"  @submit.prevent="SaveData" lazy-validation>
            <v-toolbar flat color="cyan lighten-3" dense>
              <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="newNameGroup" :rules="nameRules" label="Group Name" outlined clearable required></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <template v-for="(element, index) in mainGroup">
                    <v-col class="col-md-3" :key="index">
                      <v-card>
                        <div class="d-flex flex-no-wrap justify-space-between">
                          <v-card-title class="text-body-1">{{ element.name }} {{ element.lastName }}</v-card-title>
                          <v-checkbox v-model="element.selectGroup" color="success" hide-details :disabled="element.group != '' && editedGroupUID == -1" :indeterminate="element.group != '' && editedGroupUID == -1"></v-checkbox>
                        </div>
                      </v-card>
                    </v-col>
                  </template>
                </v-row> -->
              </v-container>
            </v-card-text>
            <v-card-actions>
               <v-btn small text color="error" v-if="editedGroupUID != -1" @click="dialogDelete = true"><v-icon>mdi-delete</v-icon>Delete Class</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue-grey lighten-2" outlined @click="closeDialogNewGroup">Cancel</v-btn>
              <v-btn large color="success" outlined type="submit">Save <v-icon>mdi-check</v-icon></v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="900">
        <v-card>
          <v-toolbar flat color="cyan lighten-3">
            <v-toolbar-title>Choose students for this class</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text color="blue-grey lighten-1" @click="dialog = false">
              <v-icon left>
                mdi-window-close
              </v-icon>
              Close
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-5">
              <v-col cols="8" sm="8" md="8">
                <v-text-field v-model="search" :rules="emailRules" label="Email of student" outlined clearable required></v-text-field>
              </v-col>
              <v-col class="my-md-2" cols="2">
                <v-btn elevation="2" @click="SearchStudent" large color="success"><v-icon dark>mdi-account-search</v-icon> Search</v-btn>
              </v-col>
              <!-- <v-col class="my-md-2" cols="2" v-if="tempTokenClever != ''">
                <v-btn elevation="2" @click="OpenListClassClever" large color="success"><v-icon dark>mdi-account-search</v-icon> Clever Class</v-btn>
              </v-col> -->
            </v-row>
            <v-row class="pa-5" v-if="messageErrorStudent != ''">
              <v-alert class="col-12" border="top" colored-border type="error" elevation="2">
                {{ messageErrorStudent }}
              </v-alert>
            </v-row>
            <v-card class="mx-5" max-width="450" outlined v-if="searchUser != ''">
              <v-row>
                <v-col cols="6">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ searchUser.name}} {{ searchUser.lastName}}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ searchUser.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6" class="my-auto">
                  <v-card-actions>
                    <v-btn outlined rounded   @click="addToClass(searchUser)" :disabled="buttonLoadAddStudentClass" :loading="buttonLoadAddStudentClass">
                      <v-icon dark>mdi-plus</v-icon> 
                      Add Student
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
            <v-row v-if="clasesClever.length > 0">
              <template v-for="(clase, index) in clasesClever">
                    <v-col class="col-md-4" :key="index">
                      <v-card @click="AddCleverClass(clase.students)">
                        {{clase.name}} / grade: {{ clase.grado}} / students: {{clase.length}}
                      </v-card>
                    </v-col>
                  </template>
            </v-row>
          </v-card-text>
          <!-- <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
  
          <div class="pa-5">
          <v-data-table :headers="headers" :items="ListImportStudents" :search="search" class="elevation-1 ma-1">
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon class="mr-2" @click="addToClass(item)" color="green">
                mdi-plus
              </v-icon>
            </template>
          </v-data-table>
          </div> -->
        </v-card>
      </v-dialog>
    </v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-toolbar flat color="cyan lighten-4" dense>
            <v-toolbar-title>Are you sure you want to delete?</v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey lighten-2" large @click="dialogDelete = false">Cancel</v-btn>
            <v-btn color="error" large @click="deleteGroup">OK <v-icon>mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <v-dialog v-model="dialogDeleteStudent" max-width="500px">
        <v-card>
          <v-toolbar flat color="cyan lighten-4" dense>
            <v-toolbar-title>Are you sure you want to delete student {{studentForDelete.name}} </v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey lighten-2" large @click="dialogDeleteStudent = false">Cancel</v-btn>
            <v-btn color="error" large @click="ConfirmnDeleteStudentFromMain">YES, DELETE <v-icon>mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogConfirmRandom" max-width="500px">
        <v-card>
          <v-toolbar flat color="cyan lighten-4" dense>
            <v-toolbar-title>are you sure to rearrange all students </v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey lighten-2" large @click="dialogConfirmRandom = false">Cancel</v-btn>
            <v-btn color="success" large @click="RandomStudents">Yes, Continue<v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogConfirmDeleteTest" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <p class="mb-0 text-h6 mx-auto">You want to delete all Progress Check from {{TextCurrentMonth}}</p>
        </v-toolbar>
        <div class="text-center py-3">
          <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="dialogConfirmDeleteTest = false">Cancel</v-btn>
          <v-btn color="error" large @click="EliminarTest">Yes, Delete <v-icon>mdi-delete</v-icon></v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmGenerateTest" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <p class="mb-0 text-h6 mx-auto">Generate {{TextCurrentMonth}} Progress Check </p>
        </v-toolbar>
        <v-card-text class="text-subtitle-1">
            This will overwrite the Progress Check for students who have already taken 
          </v-card-text>
        <div class="text-center py-3">
          <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="dialogConfirmGenerateTest = false">Cancel</v-btn>
          <v-btn color="success" large @click="GenerateTest">Continue <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmacionCambioNivel" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <p class="mb-0 text-h6 mx-auto">Are you sure you want to change the student's level?</p>
        </v-toolbar>
        <v-row justify="center" class="mx-5">
          <v-col cols="4">
            {{ TextoMisionInicial}}
            <br>
            {{ TextoNivelInicial}}
          </v-col>
          <v-col cols="2">
            <v-icon large color="blue darken-2">mdi-arrow-right-box</v-icon>
          </v-col>
          <v-col cols="4">
            {{TextoMisionFinal}}
            <br>
            {{ TextoNivelFinal}}
          </v-col>
        </v-row>
        <div class="text-center py-3">
          <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="cancelChangeLevel">Cancel</v-btn>
          <v-btn large color="success" outlined type="submit" class="ml-2"  :disabled="loadConfirmChangeLevel" :loading="loadConfirmChangeLevel" @click="ChangeLevelGameAllClass" v-if="SelectStudentProgress == -1">Save All Class<v-icon>mdi-check</v-icon></v-btn>
          <v-btn large color="success" outlined type="submit" class="ml-2"  :disabled="loadConfirmChangeLevel" :loading="loadConfirmChangeLevel" @click="ChangeLevelGame" v-else>Save <v-icon>mdi-check</v-icon></v-btn>
        </div>
      </v-card>
    </v-dialog>
      <v-overlay :value="dialogLoading"></v-overlay>
      <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
        <v-card color="rgba(0, 143, 135, 141)" dark>
          <v-card-text>
            Wait please
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    <v-dialog v-model="dialogJuegos" max-width="780px" persistent>
      <v-card>
        <!-- <v-toolbar flat color="cyan lighten-4" dense>
          <p class="mb-0 text-h6 mx-auto">Seleccione el juego</p>
        </v-toolbar> -->
        <v-card-title class="justify-center">
            Seleccione el juego
          </v-card-title>
        <v-radio-group v-model="juegoGrupalSeleccionado">
        <v-row>
          <v-col offset="6" cols="3" class="game1-mp">
            <img  :src="require('../../../assets/admin/Game1MP.png')" :class="(Resaltado11 || Resaltado12 || Resaltado13 || Resaltado14 || Resaltado15 || Resaltado16 || Resaltado17 || Resaltado18 || Resaltado19)? 'ResaltarFila': ''">
            <p >Game 1</p>
          </v-col>
          <v-col cols="3" class="game1-mp">
            <img  :src="require('../../../assets/admin/Game2MP.png')" :class="(Resaltado22 || Resaltado23 || Resaltado24 || Resaltado25 || Resaltado26 || Resaltado27 || Resaltado28 || Resaltado29)? 'ResaltarFila': ''">
            <p >Game 2</p>
          </v-col>
        </v-row>
        
          <v-row class="row-multiplayer-table mt-1" :class="[Resaltado11? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM1Juego1'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Los Sonidos</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado11 = true" @mouseleave="Resaltado11 = false">
                <v-radio value="MPM1Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            
            </v-col>
            <v-col cols="3" class="py-1">
  
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado12 || Resaltado22)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM2Juego1' || juegoGrupalSeleccionado == 'MPM2Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Las Sílabas directas</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado12 = true" @mouseleave="Resaltado12 = false">
                <v-radio value="MPM2Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado22 = true" @mouseleave="Resaltado22 = false">
                <v-radio value="MPM2Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado13 || Resaltado23)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM3Juego1' || juegoGrupalSeleccionado == 'MPM3Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Palabras de 2 Sílabas</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado13 = true" @mouseleave="Resaltado13 = false">
                <v-radio value="MPM3Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado23 = true" @mouseleave="Resaltado23 = false">
                <v-radio value="MPM3Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado14 || Resaltado24)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM4E1Juego1' || juegoGrupalSeleccionado == 'MPM4E1Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Sílabas con extensiones</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado14 = true" @mouseleave="Resaltado14 = false">
                <v-radio value="MPM4E1Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado24 = true" @mouseleave="Resaltado24 = false">
                <v-radio value="MPM4E1Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado15 || Resaltado25)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM4E2Juego1' || juegoGrupalSeleccionado == 'MPM4E2Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Sílabas inversas</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado15 = true" @mouseleave="Resaltado15 = false">
                <v-radio value="MPM4E2Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado25 = true" @mouseleave="Resaltado25 = false">
                <v-radio value="MPM4E2Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado16 || Resaltado26)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM4E3Juego1' || juegoGrupalSeleccionado == 'MPM4E3Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Sílabas de una letra</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado16 = true" @mouseleave="Resaltado16 = false">
                <v-radio value="MPM4E3Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado26 = true" @mouseleave="Resaltado26 = false">
                <v-radio value="MPM4E3Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado17 || Resaltado27)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM5Juego1' || juegoGrupalSeleccionado == 'MPM5Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Palabras de 3 sílabas</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado17 = true" @mouseleave="Resaltado17 = false">
                <v-radio value="MPM5Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado27 = true" @mouseleave="Resaltado27 = false">
                <v-radio value="MPM5Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado18 || Resaltado28)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM6Juego1' || juegoGrupalSeleccionado == 'MPM6Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Sílabas trabadas</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado18 = true" @mouseleave="Resaltado18 = false">
                <v-radio value="MPM6Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado28 = true" @mouseleave="Resaltado28 = false">
                <v-radio value="MPM6Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
          <v-row class="row-multiplayer-table mt-1" :class="[(Resaltado19 || Resaltado29)? 'ResaltarFila': '',(juegoGrupalSeleccionado == 'MPM7Juego1' || juegoGrupalSeleccionado == 'MPM7Juego2'?'RowColumnGameSelected':'') ]">
            <v-col cols="6" class="py-1 white--text font-weight-bold" >Lectura de oraciones</v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado19 = true" @mouseleave="Resaltado19 = false">
                <v-radio value="MPM7Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
            <v-col cols="3" class="py-1" @mouseover="Resaltado29 = true" @mouseleave="Resaltado29 = false">
                <v-radio value="MPM7Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="position-cbmp">
                  <template v-slot:label>
                    <div  class="my-2 d-flex align-center flex-column rounded-lg">
                    <span></span>
                    </div>
                  </template> 
                </v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <v-row justify="center" class="mx-5">
          <v-col cols="12" style="display:none;">
            <v-row>
              <v-col cols="4">
                <v-subheader>
                  Mision
                </v-subheader>
              </v-col>
              <v-col class="d-flex" cols="8" sm="8">
                <v-select v-model="MultiplayerMisionSelected" :items="missionMultiplayer" item-text="name" item-value="id" dense solo outlined></v-select>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" class="flex-center">
            <v-radio-group v-model="juegoGrupalSeleccionado" row>
              <v-radio value="MPM1Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Los Sonidos</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM2Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Las Sílabas directas Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM2Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Las Sílabas directas Juego 2</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM3Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Palabras de 2 Sílabas Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM3Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Palabras de 2 Sílabas Juego 2</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM4E1Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas con extensiones Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM4E1Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas con extensiones Juego 2</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM4E2Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas inversas Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM4E2Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas inversas Juego 2</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM4E3Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas de una letra Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM4E3Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas de una letra Juego 2</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM5Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Palabras de 3 sílabas Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM5Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Palabras de 3 sílabas Juego 2</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM6Juego1" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas trabadas Juego 1</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="MPM6Juego2" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 140px;" class="my-4 d-flex align-center flex-column rounded-lg">
                  <span>Sílabas trabadas Juego 2</span>
                  </div>
                </template> 
              </v-radio>
            </v-radio-group>
          </v-col> -->
          <v-card-title>
            Programe el tiempo del juego
          </v-card-title>
          <v-col cols="12" class="flex-center">
            <v-radio-group v-model="TiempoJuegoSeleccionado" row>
              <v-radio value="10" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 60px;" class="trucksicons d-flex align-center flex-column">
                    <span> 10 min</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="15" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 60px;" class="trucksicons d-flex align-center flex-column">
                    <span> 15 min</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="30" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                  <div style="width: 60px;" class="trucksicons d-flex align-center flex-column">
                    <span> 30 min</span>
                  </div>
                </template> 
              </v-radio>
              <v-radio value="45" color="success" active-class="selected-radio"  on-icon="mdi-check" class="mx-5">
                <template v-slot:label>
                 <div style="width: 60px;" class="trucksicons d-flex align-center flex-column">
                  <span> 45 min</span>
                 </div>
                </template> 
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <div class="text-center py-3">
          <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="dialogJuegos = false">Cancel</v-btn>
          <v-btn large color="success" outlined type="submit" class="ml-2" @click="IniciarJuegosGrupos">Iniciar Juegos<v-icon>mdi-check</v-icon></v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
  </template>
  <script>
  import { db, functions } from '../../../plugins/firebase'
  import firebase from 'firebase'
  // import draggable from 'vuedraggable'
  import QrcodeVue from 'qrcode.vue'
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas'
  import { Container, Draggable } from "vue-dndrop";
  import { applyDrag, generateItems } from "../../../components/DragDrop";
  import Bar from '../../../components/BarChart'
  import Pie from '../../../components/PieChart'
  let i = 0;
  
  function id() {
    return `item-${++i}`;
  }
  function generate(num) {
    return generateItems(1, (i) => ({
      id: id(),
      data: `Alumnos  ${num} - ${i + 1}`,
    }));
  }
  export default {
    // async asyncData ({ params, redirect, store }) {
    //   let uidLogin = store.state.users.user.uid;
    //   console.log(uidLogin)
    //   let classroom = await db.collection('SchoolTeacher').doc(uidLogin).collection('Classrooms').doc(params.class).get().then((doc) => {
    //     console.log(doc.data());
    //     return doc.data();
    //   }).catch((error) => {
    //     console.log(error)
    //   });
    //   return classroom
    // },
    components: { QrcodeVue, Container, Draggable, Bar, Pie },
    data () {
      return {
        barChartOptions: {
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        },
        barCharData: {
            labels: ['diciembre'],
            datasets: [
              {
                label: 'Success',
                data: [12],
                backgroundColor: '#008f88'
              },
              {
                label: 'Errors',
                data: [12],
                backgroundColor: '#ffa15d'
              },
            ]
          },
        CharAciertosOptions: {
          responsive: true,
        },
        CharAciertos : {
            labels: ['success %', 'errors %'],
            datasets: [{
              label: 'Visits',
              data: [100, 30],
              backgroundColor: ['#008f88', '#ffa15d']
            }]
          },
        tab:'',
        size: 150,
        alertError: '',
        alertSuccess: '',
        user: {},
        searchUser: '',
        classroom: '',
        mainGroup: [],
        groups: [],
        ListImportStudents: [],
        dialog: false,
        dialogLoading: false,
        dialogListStudents: false,
        dialogListQR: false,
        selectedExport: [],
        selectedTest: [],
        search: '',
        newNameGroup: '',
        nameRules: [
          v => (v && v.length >= 2) || 'Minimum length is 2 characters',
          v => (v && v.length <= 30) || 'Name must be less than 30 characters'
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
        ],
        headers: [
          { text: 'name', align: 'start', value: 'name', class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'last Name', value: 'lastName',class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'email', value: 'email', class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'Actions', value: 'actions', sortable: false, class: 'blue-grey lighten-4 text-subtitle-2' }
        ],
        idMainGroup: '',
        listStudentsData:[],
        headerTableStudentsGames: [
          { text: 'Full Name', value: 'name', class: 'blue-grey lighten-4 text-subtitle-2'},
          { text: 'Email', value: 'email', class: 'blue-grey lighten-4 text-subtitle-2'},
          { text: 'Current Mission', value: 'mision', class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'Current Stage', value: 'etapa',class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'Current Game', value: 'juego', class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'Coins', value: 'monedas',class: 'blue-grey lighten-4 text-subtitle-2' },
          { text: 'Modify', value: 'actions', sortable: false, class: 'blue-grey lighten-4 text-subtitle-2' }
        ],
        newMission: '',
        newStage: '',
        newGame: '',
        currentMission: '',
        currentStage: '',
        currentGame: '',
        IdStudentEdit: -1,
        headerTableQR: [
          { text: 'Full Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Export', value: 'export' }
        ],
        headerTableTest: [
          { text: 'Full Name', value: 'name' },
        ],
        headerTableListTest: [
          { text: 'Full Name', value: 'name' ,class: 'blue lighten-5',width:'20%'},
          { text: 'Progress Check History', value: 'months', sortable: false ,class: 'blue lighten-5',width: '50%'},
          { text: 'Options', value: 'actions', sortable: false, class: 'blue lighten-5', align: 'center' }
        ],
        listExportQR: [],
        listCanvasQr: [],
        listTest:[],
        dialogNewGroup: false,
        valid: true,
        editedGroupUID: -1,
        dialogDelete: false,
        dialogDeleteStudent:false,
        studentForDelete:{},
        messageErrorStudent: '',
        tempTokenClever: '',
        clasesClever: [],
        loadCode: false,
        CodeAccess: '',
        tooltipClassCode: false,
        loadingManual: true,
        urlManual:'',
        avanceJuego: 10,
        prevAvance: '',
        SelectStudentProgress: '',
        MisionInicial: 1,
        TextoNivelInicial:'',
        TextoNivelFinal:'',
        dialogConfirmacionCambioNivel: false,
        TextoMisionInicial: '',
        TextoMisionFinal: '',
        loadConfirmChangeLevel: false,
        studentGameAlready: false,
        missionsNames: [
          {
            name: 'Los Sonidos',
            img: 'te-mision1.jpg',
            misiones: ['1. o-a-i-u-e', '2. m-p-s-l-t-d-r-c-ce', '3. n-f-b-j-g-ge-ch-ñ-v', '4. ll-qu-z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Las Sílabas',
            img: 'te-mision2.jpg',
            misiones: ['1. m-p-s-l-t-d', '2. r-c-ce-n-f-b-j', '3. g-ch-ñ-v-ll-qu', '4. z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Palabras',
            img: 'te-mision3.jpg',
            misiones: ['1. m-p-s-l-t-d', '2. r-c-ce-n-f-b-j', '3. g-ch-ñ-v-ll-qu', '4. z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Extensiones, sílabas inversas, sílabas con inicio',
            img: 'te-mision4.jpg',
            misiones: ['1. Extensiones', '2. Sílabas inversas', '3. Sílabas', '4. Todas las anteriores', '5. Evaluación final']
          },
          {
            name: 'Tres Sílabas',
            img: 'te-mision5.jpg',
            misiones: ['1. m-p-s-l-t-d', '2. r-c-ce-n-f-b-j', '3. g-ch-ñ-v-ll-qu', '4. z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Sílabas trabadas',
            img: 'te-mision6.jpg',
            misiones: ['1. bl-br-cl', '2. fl-fr-pl-pr', '3. gl-gr-dr-tr', '4. gue-gui-güe-güi', '5. Evaluación final']
          },
          {
            name: 'Lectura de Oraciones',
            img: 'te-mision7.png',
            misiones: ['1. Sustantivos', '2. Verbos', '3. Adjetivos', '4. Oraciones', '5. Evaluación final']
          }
        ],
        missionMultiplayer:
        [
          {
            id:1,
            name: 'Mision 1: Los Sonidos',
            juegos: [
              { id:1, name:'Memoria', imagen:'juego1.png' },
              { id:2, name:'Colaborativo', imagen:'juego2.png' }
            ]
          },
        ],
        misionActualAlumno: '',
        etapaActualAlumno: '',
        groupsDragDrop: [
  
          ],
          logs: {
              "get-child-payload": true,
  
              drop: true,
          },
          RemovedElement: null,
          AddedElement: null,
          ColumnRemoved: '',
          ColumnAdded: '',
          dialogJuegos: false,
          tiemposJuego: 
           [
            { label: '1 min', value: '1' },
            { label: '15 min', value: '15' },
            { label: '30 min', value: '30' },
            { label: '45 min', value: '45' },
            
          ],
          juegoGrupalSeleccionado : 'MPM1Juego1',
          esTiempoActivo: false,
          TiempoJuegoSeleccionado: '15',
          TiempoTemporizador: 0,
          MultiplayerMisionSelected:1,
          dialogTableTest:false,
          StepTest: 0,
          IsOptionGenerateTest: false,
          IsOptionReportTest: false,
          ItemsDatesTests:[  ],
          ListResultResportTest:[],
          ListFinalStudents: [],
          TextoCabeceraTabla:"",
  
          dialogConfirmDeleteTest:false,
          dialogConfirmGenerateTest:false,
          TabTest:null,
          MonthNames : ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"],
          MonthShortNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
          ListManageTest: [],
          ExistTestCurrentMonth: false,
          TextCurrentMonth: "",
          TestReportMonthSelected: "",
          OptionsMonthsReport: [],
          OptionsStudentsReport:[],
          TestReportStudentSelected:{},
          ListStudentsOptionsReport:{},
          GroupSelected:[],
          Resaltado11: false,
          Resaltado12: false,
          Resaltado22:false,
          Resaltado13: false,
          Resaltado23:false,
          Resaltado14: false,
          Resaltado24:false,
          Resaltado15: false,
          Resaltado25:false,
          Resaltado16: false,
          Resaltado26:false,
          Resaltado17: false,
          Resaltado27:false,
          Resaltado18: false,
          Resaltado28:false,
          Resaltado19: false,
          Resaltado29: false,
          NamesGroups: ["1st Group","2nd Group","3rd Group","4th Group","5th Group","6th Group","7th Group","8th Group"],
          dialogConfirmRandom: false,
          buttonLoadAddStudentClass: false
      }
    },
    created () {
      this.GetDataClassroom();
      this.GetDataTeacher();
    },
    methods: {
      VerifyIsClever () {
        db.collection('TempTokensClever').doc(this.user.UserUID).get().then((doc) => {
          console.log("VerifyIsClever");
          console.log(doc.data());
          if(!doc.exists){
            return;
          }
          let dia = new Date(doc.data().Date);
          let hoy = new Date();
          dia.setHours(0,0,0,0);
          hoy.setHours(0,0,0,0);
          if (dia.getTime() == hoy.getTime()){
            this.tempTokenClever = doc.data().Token;
          }
        })
      },
      cambioTab () {
        //console.log('cambio de tab' + this.tab);
        switch (this.tab) {
          case 0:
            //console.log('entro a la primera')
            break;
          case 1:
            //console.log('entro a la segunda')
            //this.openModalListStudents();
            break;
          case 2:
            //console.log('entro a la tercera')
            this.openModalQR();
            break;
          case 3:
            this.ShowManageTest()     
            break;
        }
      },
      OpenDialogNewGroup () {
        console.log('agregar nueva clase')
        this.dialogNewGroup = true;
      },
      EditGroup (group) {
        console.log(group)
        this.dialogNewGroup = true;
        this.editedGroupUID = group.id;
        this.newNameGroup = group.name
      },
      async GetDataClassroom () {
        let uidLogin = this.$store.state.user.uid;
        this.dialogLoading = true;
        // console.log(this.$route.params.class)
        await db.collection('SchoolTeacher').doc(uidLogin).collection('Classrooms').doc(this.$route.params.class).onSnapshot((doc) => {
          this.classroom = doc.data();
          if (Object.prototype.hasOwnProperty.call(this.classroom, "CodeAccess")) {   
            this.CodeAccess= this.classroom.CodeAccess;
          }
          this.mainGroup = [];
          
          let preMainGroup = doc.data().MainGroup;
          // console.log(preMainGroup)
          
          let newMainGroup = [];
          if (Object.keys(preMainGroup).length !== 0) {
            let ids = Object.keys(preMainGroup);
            ids.forEach(element => {
              newMainGroup.push({ id: element, name: preMainGroup[element].Name, lastName: preMainGroup[element].LastName, LastLoginPlatform: (typeof(preMainGroup[element].LastLoginPlatform)!=='undefined'?preMainGroup[element].LastLoginPlatform:''), email: preMainGroup[element].Email, selectGroup: false, group: preMainGroup[element].Group, studentId: preMainGroup[element].StudentId })
            });
          }
          newMainGroup.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
           this.mainGroup = newMainGroup;
          //organizacion de grupos
          this.groups = [];
          this.groupsDragDrop = [];
          let grupos =doc.data().Groups;
          const ordered = Object.keys(grupos).sort().reduce(
            (obj, key) => { 
              obj[key] = grupos[key]; 
              return obj;
            }, 
            {}
          );
          grupos = ordered;
          console.log(grupos);
          if (Object.keys(grupos).length !== 0) {        
      
            let ids = Object.keys(grupos);
            ids.forEach(element => {
              let IsActiveRoom = (grupos[element].IdRoom == "" || grupos[element].IdRoom == undefined)? "":grupos[element].IdRoom;
              this.groups.push({name: grupos[element].Name, id: element, endTime: grupos[element].EndTime, IdRoom: IsActiveRoom});
              let students = grupos[element].Students;
              let arrayStudents= [];
  
              if (Object.keys(students).length !== 0) {
                let idstudents = Object.keys(students);
                
                idstudents.forEach(elementStudent => {
                  // console.log(students[elementStudent]);
                  arrayStudents.push({ id: elementStudent, name: preMainGroup[elementStudent].Name, lastName: preMainGroup[elementStudent].LastName, LastLoginPlatform: (typeof(preMainGroup[elementStudent].LastLoginPlatform)!=='undefined'?preMainGroup[elementStudent].LastLoginPlatform:'') });
                });
              }
              arrayStudents.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
              this.groupsDragDrop.push(arrayStudents);
            });
          }
          this.dialogLoading = false;
        }, (error) => {
          console.log(error);
        })
  
      },
      async GetDataTeacher () {
        let uidLogin = this.$store.state.user.uid;
        await db.collection('SchoolTeacher').doc(uidLogin).get().then((doc) => {
          this.user = doc.data();
          this.tooltipClassCode = doc.data().FirstTime;
          if (doc.data().FirstTime) {
            setTimeout(() => {
              // let me = this;
              // db.collection('CodeAccessStudents').doc(this.CodeAccess).get().then((revision)=>{
              //   console.log(revision.data().TeacherId)
              //   console.log(me.user.UserUID)
              //   if (revision.data().TeacherId != me.user.UserUID) {
              //     console.log('no es el mismo');
              //     db.collection("SchoolTeacher").doc(me.user.UserUID).collection('Classrooms').doc(me.$route.params.class).update({
              //         "CodeAccess": ''
              //     })
              //     me.CodeAccess = "";
              //   }
              //   me.loadCode = false;
              // });
              db.collection("SchoolTeacher").doc(uidLogin).update({FirstTime:false});
            }, 3000);      
          }
          this.VerifyIsClever();
        }, (error) => {
          console.log(error);
        })
      },
      openModalImportStudent () {      
        db.doc(this.user.Reference.path + '/StudentsData/P1').get().then((doc) => {
          let listTotalStudents = doc.data().Students;
          let newTotalStudents = [];
          if (Object.keys(listTotalStudents).length !== 0) {
            let ids = Object.keys(listTotalStudents);
            ids.forEach(element => {
  
                newTotalStudents.push({ id: element, name: listTotalStudents[element].Name, lastName: listTotalStudents[element].LastName, email: listTotalStudents[element].Email, teacher: listTotalStudents[element].UserUIDSTeacher, create: true, studentId: listTotalStudents[element].StudentId })
              
            });
          }
          this.ListImportStudents = newTotalStudents;
          this.dialog = true;
        })
      },
      SearchStudent () {
        const getStudent = this.ListImportStudents.find(student => student.email === this.search);
        if (getStudent ===  undefined) {
          this.messageErrorStudent = 'Student not found'
          return
        }
        if (getStudent.teacher != '') {
          this.messageErrorStudent = 'Student is already assigned'
          return
        }
        this.messageErrorStudent = '';
        this.searchUser = getStudent;
      },
      closeDialogStudent () {
        this.dialog = false
        this.messageErrorStudent = '';
        this.searchUser = '';
        this.search = '';
      },
      async addToClass (item) {
        this.dialogLoading = true;
        this.buttonLoadAddStudentClass = true;
        console.log(item)
        try {
          //let classroom = await db.collection("SchoolTeacher").doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).get();
          //Para obtener LastLoginPlatform de Students
          let studentData = await db.collection("Students").doc(item.id).get();
          let student=studentData.data();
          
          //let classroomData = classroom.data();
          let batch = db.batch();
          batch.update(db.doc(this.user.Reference.path + '/StudentsData/P1'), { [`Students.${item.id}.UserUIDSTeacher`]: this.user.UserUID });
          // db.doc(this.user.reference.path + '/StudentsData/P1').update({ [`Students.${item.id}.userUIDSTeacher`]: this.user.userUID })
          let step1 = db.collection("SchoolTeacher").doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
          batch.update(step1, { [`MainGroup.${item.id}`]: { Name: item.name, LastName: item.lastName, LastLoginPlatform: (typeof(student.LastLoginPlatform)!=='undefined'?student.LastLoginPlatform:''), Email: item.email, Group:'', StudentId: item.studentId } });
          let step2 = db.collection("SchoolTeacher").doc(this.user.UserUID);
          // let idClass = this.$route.params.class;
          let partialSumStudents = this.user.Classrooms[this.$route.params.class].NumberStudents;
          partialSumStudents++;
          batch.update(step2, { [`Classrooms.${this.$route.params.class}.NumberStudents`]: firebase.firestore.FieldValue.increment(1) });
          // --- ESTP ES EN CASO DE QUE QUIERA AGREGAR EL TEST AUTOMATICO AL AGREGAR UN USUARIO
          // const date = new Date();
          // let day = date.getDate();
          // let month = date.getMonth() + 1;
          // let year = date.getFullYear();
          // let fechaExam= month+"-"+day+"-"+year;
          // let IsProgramSameDay = false;
          // if (Object.prototype.hasOwnProperty.call(classroomData, 'Tests')) {
          //   if (Object.prototype.hasOwnProperty.call(classroomData.Tests, fechaExam)) {
          //     console.log(classroomData.Tests);
          //     console.log(classroomData.Tests[fechaExam]);
          //     if(classroomData.Tests[fechaExam].Estado){
          //       IsProgramSameDay= true;
          //     }
          //   }
          // }
          // let step3 =db.collection("Students").doc(item.id);
          // batch.update(step3, { IdExam:fechaExam });
          // let step4 = db.collection("SchoolTeacher").doc(this.user.UserUID).collection("Classrooms").doc(this.$route.params.class);
          // if (IsProgramSameDay) {
          //   batch.update(step4, { [`Tests.${fechaExam}.Students`]:  firebase.firestore.FieldValue.arrayUnion(item.id) }); 
          // }else{
          //   batch.set(step4, { Tests: { [`${fechaExam}`] : {Estado:true,Students:[item.id]} } },{ merge: true });
          // }
          await batch.commit()
          this.dialog = false;
          this.dialogLoading = false;
          this.buttonLoadAddStudentClass =false;
        } catch (error) {
          this.dialogLoading = false;
          this.buttonLoadAddStudentClass =false;
          this.alertError = "Error";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
        }
      },
      SaveData () {
        if (this.newNameGroup === '' || this.newNameGroup.length < 3) {
          return
        }
        if (this.editedGroupUID==-1) {
          this.AddGroup();
        }else{
          this.UpdateGroup();
        }
      },
      AddGroup () {
        let slug = this.newNameGroup.replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase();
        slug = slug.replace(/^\s+|\s+$/gm, '');
        slug = slug.replace(/\s+/g, '-');
        let groupsAux = this.mainGroup;
        db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).update({ [`Groups.${[slug]}`]: { Name: this.newNameGroup, Students: {} } }).then((res) => {
          // console.log(res.data())
          this.newNameGroup = '';
          // let partialSumGroups = this.user.Classrooms[this.$route.params.class].NumberGroup;
          // partialSumGroups++;
          db.collection("SchoolTeacher").doc(this.user.UserUID).update({ [`Classrooms.${this.$route.params.class}.NumberGroup`]: firebase.firestore.FieldValue.increment(1) })
          groupsAux.forEach(element => {
            console.log(element)
            if(element.selectGroup && element.group == ''){
              db.collection("SchoolTeacher").doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).update({ [`MainGroup.${[element.id]}.Group`]: slug })
            }
          });
          this.dialogNewGroup = false;
        });
      },      
      UpdateGroup(){
        console.log('entro a actualizar')
        let groupsAux = this.mainGroup;
        db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).update({ [`Groups.${[this.editedGroupUID]}.Name`]: this.newNameGroup }).then((res) => {
          this.newNameGroup = '';
          this.dialogNewGroup = false;
        });
      },
      async AddGroupDirectly(){
        this.dialogLoading = true;
        //verificar si llego al maximo de grupos permitidos
        console.log(this.groups.length);
        let nroGrupos =this.groups.length;
        if (nroGrupos > 8) {
          this.alertError = "Maximum group limit: 9";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
          this.dialogLoading = false;
          return;
        }
        let slug = this.NamesGroups[nroGrupos].replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase();
        slug = slug.replace(/^\s+|\s+$/gm, '');
        slug = slug.replace(/\s+/g, '-');
  
        await db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).update({ [`Groups.${[slug]}`]: { Name: this.NamesGroups[nroGrupos], Students: {} } });
        await db.collection("SchoolTeacher").doc(this.user.UserUID).update({ [`Classrooms.${this.$route.params.class}.NumberGroup`]: firebase.firestore.FieldValue.increment(1) })
        this.dialogLoading = false;
      },
      ClickButtonDeleteStudent (student) {
        console.log(student)
        this.studentForDelete = student
        this.dialogDeleteStudent = true;
      },
      ConfirmnDeleteStudentFromMain () {
        let student = this.studentForDelete;
        console.log(student)
        // return;
        // console.log(this.$route.params.class)
        this.dialogDeleteStudent = false;
        this.dialogLoading = true;
        
        try {
          let batch = db.batch();
          batch.update(db.doc(this.user.Reference.path + '/StudentsData/P1'), { [`Students.${student.id}.UserUIDSTeacher`]: '' });
          let step1 = db.collection("SchoolTeacher").doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
          batch.update(step1, { [`MainGroup.${[student.id]}`]: firebase.firestore.FieldValue.delete() });
          let step2 = db.collection("SchoolTeacher").doc(this.user.UserUID);
          batch.update(step2, { [`Classrooms.${this.$route.params.class}.NumberStudents`]: firebase.firestore.FieldValue.increment(-1) });
          batch.commit()
            .then(() => {
              this.dialogLoading = false;
              if (student.email == '') {
                db.collection("StudentsUserClever").doc(student.id).update({ 'Teacher': '' })
              }
              console.log('regresado correctamente')
              this.studentForDelete = {};
            })
        } catch (error) {
          console.log(error)
          this.dialogLoading = false;
          this.studentForDelete = {};
  
        }
      },
      openModalQR () {
        // this.dialogListQR = true;
        console.log(this.mainGroup);
        let StudentQR = [];
        this.mainGroup.forEach(student => {
          StudentQR.push({ id: student.id, name: student.name + ' ' + student.lastName, email: student.email, export: false, studentId: student.studentId })
        });
        console.log(StudentQR)
        this.listExportQR = StudentQR;
      },
      ExportQR () {
        console.log(this.selectedExport)
        this.listCanvasQr = [];
        // this.listExportQR.forEach(element => {
        //   if (element.export) {
        //     this.listCanvasQr.push({ qr:element.email + " " + element.studentId, name:element.name, email: element.email });
        //   }
        // });
        this.selectedExport.forEach(element => {
          this.listCanvasQr.push({ qr:element.email + " " + element.studentId, name:element.name, email: element.email });
        });
        if (this.listCanvasQr.length == 0) {
          console.log('seleccione alumnos');
          return;
        }
     
        window.html2canvas = html2canvas;
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.html(document.querySelector("#qr-code"), {
          callback: function(pdf) {
            pdf.save("listaQR.pdf");
          }
        })
        this.listExportQR.forEach(student => {
          student.export = false;
        });
        this.dialogListQR = false;
        
      },
      closeDialogNewGroup () {
        this.dialogNewGroup = false
        this.$refs.form.resetValidation()
        this.$nextTick(() => {
          this.newNameGroup = ''
          this.editedGroupUID = -1
        })
        this.mainGroup.forEach(element => {
          if (element.group=='') {
            element.selectGroup= false;
          }
        });
      },
      deleteGroup () {
        console.log(this.editedGroupUID)
        this.deleteGroups(this.editedGroupUID).then((answer) => {
          if (answer.result) {
            this.alertSuccess = "deleted successfully";
          } else {
            this.alertError = answer.message;
          }
        });
        this.dialogDelete = false;
        this.closeDialogNewGroup()
      },
      async deleteGroups (id) {
        let answer = { resutl: '', message: '' }
        try {
          // if (this.classroom.numberGroup !== 0 && this.classroom.numberStudents !== 0) {
          //   throw new Error('need delete students of this class')
          // }
          const getIdGroup = this.groups.findIndex(group => group.id === id);
          let idsDeleted = [];
          console.log(this.groups[getIdGroup]);
          this.groupsDragDrop[getIdGroup].forEach(element => {
            idsDeleted.push(element.id)
          });
          console.log(idsDeleted);
  
          let classroom = this.$route.params.class;
          // let partialSumGroups = this.user.Classrooms[this.$route.params.class].numberGroup;
          // partialSumGroups--;
          this.dialogLoading = true;
          // const deleteGroup = functions.httpsCallable('deleteGroup')
          // await deleteGroup({ idgroup: id, idTeacher: this.user.UserUID, classroom: classroom, nroGroups: partialSumGroups, idsStudents: idsDeleted })
          //   .then(() => {
          //     this.dialogLoading = false;
          //     answer.result = true;
          //   })
          var batch = db.batch();
          batch.update(db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(classroom),{ [`Groups.${[id]}`]: firebase.firestore.FieldValue.delete() })
          batch.update(db.collection('SchoolTeacher').doc(this.user.UserUID), { [`Classrooms.${classroom}.NumberGroup`]: firebase.firestore.FieldValue.increment(-1) })
          idsDeleted.forEach(element => {
            batch.update(db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(classroom), { [`MainGroup.${[element]}.Group`]: '' });
          });
          await batch.commit();
          this.dialogLoading = false;
          answer.result = true;
        } catch (error) {
          console.log(error)
          answer.message = error;
          answer.resutl = false;
        }
        return answer;
      },
      OpenListClassClever() {
        let clases = [];
        const url = 'https://api.clever.com/v3.0/users/'+this.user.userUID+'/sections';
        const options = {
          method: 'GET',
          headers: {Accept: 'application/json', Authorization: 'Bearer '+this.tempTokenClever}
        };
  
        fetch(url, options)
          .then(res => res.json())
          .then(json => {
            console.log(json)
            let prueba = json.data;
            //console.log(prueba)
            prueba.forEach(element => {
              console.log(element)
              clases.push({ grado: element.data.grade, name: element.data.name, length: element.data.students.length, students: element.data.students})
            });
            this.clasesClever = clases;
          })
          .catch(err => console.error('error:' + err));
      },
      AddCleverClass (students) {
        // console.log(this.user.reference.path)
        // console.log(this.$route.params.class)
        var batch = db.batch();
        students.forEach(element => {
          //console.log(element)
          batch.set(db.collection("StudentsUserClever").doc(element), {Reference: this.user.Reference, Teacher: this.user.UserUID, classroom: this.$route.params.class, school: this.user.School, licenseExpirationDate: this.user.LicenseExpirationDate });
        });
        batch.commit().then(() => {
          this.dialog = false;
        });
      },
      generateRandomString(num) {
        const characters ='ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
        let result1= '';
        const charactersLength = characters.length;
        for ( let i = 0; i < num; i++ ) {
            result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
  
        return result1;
      },
      async generateCode (){
        this.loadCode = true;
        let code = this.generateRandomString(5);
        //code.trim();
  
        code = code.trim()
        console.log(code)
        //code = 'XDQ';
        let existe = true
        let intentos  = 0;
        while (existe) {
          let demo = await db.collection('CodeAccessStudents').doc(code).get();  
          console.log(demo.exists)
          if (!demo.exists) {
            existe = false;
          }
          intentos++;
          code = this.generateRandomString(5);
          code = code.trim();
          if (intentos> 4) {
            break;
          }
        }
        console.log('paso')
        try {
          var batch = db.batch();
          batch.set(db.collection('CodeAccessStudents').doc(code), { TeacherId: this.user.UserUID, Classroom: this.$route.params.class, Reference: this.user.Reference, LicenseExpirationDate: this.user.LicenseExpirationDate, School: this.user.School });
          batch.update(db.collection("SchoolTeacher").doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class), { CodeAccess: code });
          await batch.commit();
          let me = this;
          setTimeout(() => {
            db.collection('CodeAccessStudents').doc(code).get().then((revision)=>{
              console.log(revision.data())
              if (revision.data().TeacherId != me.user.UserUID) {
                console.log('no es el mismo');
                db.collection("SchoolTeacher").doc(me.user.UserUID).collection('Classrooms').doc(me.$route.params.class).update({
                    "CodeAccess": ''
                })
                me.CodeAccess = "";
              }
              me.loadCode = false;
            }); 
          }, 1000)
          this.loadCode = false;
        } catch (error) {
          this.loadCode = false;
          console.log(error)
        }
      },
      ManualTeacher(){
        let aux = this;
        var storage = firebase.storage();
        let urlDownload = 'gs://leeleeadmin.appspot.com/resources/Manual Teacher.pdf'
        storage.refFromURL(urlDownload).getDownloadURL().then(function (url) {
          aux.loadingManual = false;
          aux.urlManual =url
        })
      },
      CambioSliderValue(){
        if (this.prevAvance != this.avanceJuego) {
          let elementList = document.querySelectorAll('.listaMision');
          let misionActual = Math.floor((this.avanceJuego)/5)+1
          let etapaActual = this.avanceJuego%5;
          if (etapaActual == 0) {
            etapaActual  =5;
            misionActual--;
          }
          console.log(this.avanceJuego);
          console.log(this.prevAvance);
          let misionPrevia = Math.floor((this.prevAvance)/5)+1
          let etapaPrevia = this.prevAvance%5;
          if (etapaPrevia == 0) {
            etapaPrevia  =5;
            misionPrevia--;
          }
          this.TextoNivelInicial =elementList[misionPrevia-1].childNodes[etapaPrevia-1].innerHTML;
          this.TextoNivelFinal = elementList[misionActual-1].childNodes[etapaActual-1].innerHTML;
          this.TextoMisionInicial ='Mision '+ (misionPrevia-1)+': ';
          this.TextoMisionFinal ='Mision '+(misionActual-1)  +': ';
          this.dialogConfirmacionCambioNivel = true;
          this.newMission = misionActual;
          this.newStage = etapaActual;
          this.newGame = 1;
          return
          let resume_table = document.getElementById("tablaAvanceJuego");
          let filaInicial = resume_table.rows[this.prevAvance]
          let filaFinal = resume_table.rows[this.avanceJuego]
          this.newMission =Math.floor((this.avanceJuego-1)/5)+1
          this.newStage = (this.avanceJuego) % 5;
          if (this.newStage ==0) {
            this.newStage=5
          }
          this.newGame = 1;
          this.TextoMisionInicial ='Mision '+ (Math.floor((this.prevAvance-1)/5)+1) ;
          this.TextoMisionFinal ='Mision '+ (Math.floor((this.avanceJuego-1)/5)+1) ;
          if (filaInicial.cells.length>1) {
            this.TextoNivelInicial = filaInicial.cells[1].innerHTML
          }else{
            this.TextoNivelInicial = filaInicial.cells[0].innerHTML
          }
          if (filaFinal.cells.length>1) {
            this.TextoNivelFinal = filaFinal.cells[1].innerHTML
          }else{
            this.TextoNivelFinal = filaFinal.cells[0].innerHTML
          }
          this.dialogConfirmacionCambioNivel = true;
          
        }
      },
      ChangeColor() {
        let elementList = document.querySelectorAll('.listaMision');
        elementList.forEach(element => {
          element.childNodes.forEach(elements => {
            elements.classList.remove('selected');
          });
        });
        let misionActual = Math.floor((this.avanceJuego)/5)+1
        let etapaActual = this.avanceJuego%5;
        if (etapaActual == 0) {
          etapaActual  =5;
          misionActual--;
        }
        elementList[misionActual-1].childNodes[etapaActual-1].classList.add('selected');
        return;
        var resume_table = document.getElementById("tablaAvanceJuego");
        for (var i = 0, row; row = resume_table.rows[i]; i++) {
          //alert(cell[i].innerText);
        
          for (var j = 0, col; col = row.cells[j]; j++) {
            //alert(col[j].innerText);
            col.className = '';
            //console.log(`Txt: ${col.innerText} \tFila: ${i} \t Celda: ${j}`);
          }
        }
        
        let fila = resume_table.rows[this.avanceJuego];
        if ((this.avanceJuego-1) % 5 == 0 ) {
        console.log(fila.cells[1].innerHTML)
          fila.cells[1].className += 'selectedCell';
          
        }else{
          console.log(fila.cells[0].innerHTML)
          fila.cells[0].className += 'selectedCell';
          
        }
      },
      async SelectStudentName (){
        console.log(this.SelectStudentProgress)
        this.studentGameAlready = true
        let demo = await db.collection('Students').doc(this.SelectStudentProgress).get();
        if (Object.prototype.hasOwnProperty.call(demo.data(), 'Alumno')) {
          let mision =demo.data().Alumno.MisionActual;
          let etapa =demo.data().Alumno.EtapaActual;
          let estadoAlumno = (mision-1)*5 + etapa
          document.getElementById("valorRange").value = estadoAlumno
          this.avanceJuego =estadoAlumno;
          this.prevAvance = estadoAlumno;
          this.misionActualAlumno =mision;
          this.etapaActualAlumno =etapa;
          this.ChangeColor();
        }else{
          this.studentGameAlready = false;
          let misionTemporal = 1;
          let etapaTemporal = 1;
          console.log(demo.data());
          if (Object.prototype.hasOwnProperty.call(demo.data(), 'MisionPredefinida')) {
            misionTemporal = demo.data().MisionPredefinida;
          }
          if (Object.prototype.hasOwnProperty.call(demo.data(), 'EtapaPredefinida')) {
            etapaTemporal = demo.data().EtapaPredefinida;
          }
          let estadoAlumno = (misionTemporal-1)*5 + etapaTemporal
          this.avanceJuego =estadoAlumno;
          this.prevAvance = estadoAlumno;
           document.getElementById("valorRange").value = estadoAlumno;
          this.misionActualAlumno =misionTemporal;
          this.etapaActualAlumno =etapaTemporal;
          this.ChangeColor();
          console.log('el alumno no ha jugado')
        }
        //console.log(demo.data())
      },
      ChangeLevelGame (){
        this.loadConfirmChangeLevel = true;
        if (!this.studentGameAlready) {
          db.collection("Students").doc(this.SelectStudentProgress).update({
               [`MisionPredefinida`]: this.newMission,
               [`EtapaPredefinida`]: this.newStage,
          })
          .then(() => {
            this.dialogConfirmacionCambioNivel = false;
            this.loadConfirmChangeLevel = false;
            this.prevAvance = this.avanceJuego;
            console.log("Document successfully updated!");
  
          }).catch(() => {
            
            this.loadConfirmChangeLevel = false;
            console.log("ocurrio un error");
          });
          return;
        }
          db.collection("Students").doc(this.SelectStudentProgress).update({
               [`Alumno.MisionActual`]: this.newMission,
               [`Alumno.EtapaActual`]: this.newStage,
               [`Alumno.JuegoActual`]: this.newGame,
          })
          .then(() => {
            this.dialogConfirmacionCambioNivel = false;
            this.loadConfirmChangeLevel = false;
            this.prevAvance = this.avanceJuego;
            console.log("Document successfully updated!");
          }).catch(() => {
            
            this.loadConfirmChangeLevel = false;
            console.log("ocurrio un error");
          });
        
      },
      cancelChangeLevel () {
        document.getElementById("valorRange").value = this.prevAvance
        this.avanceJuego =this.prevAvance;
        this.ChangeColor();
        this.dialogConfirmacionCambioNivel = false;
      },
      ChangeLevelAllClass(){
        this.SelectStudentProgress = -1; //cuando el student progress sea -1 significa toda la clase
        
        let me = this;
        setTimeout(() => {
          me.avanceJuego =1;
          me.prevAvance = 1;
          document.getElementById("valorRange").value = 1;
          me.misionActualAlumno =1;
          me.etapaActualAlumno =1;
          me.ChangeColor();
        }, 1000)
      },
      CancelChangeLevelClass(){
        this.SelectStudentProgress = '';
      },
      ChangeLevelGameAllClass(){
        console.log("se cambio toda la clase");
      },
      //aqui empezamos con el nuevo drag and drop
       onDrop(groupIndex,collection, dropResult) {
          // console.log(groupIndex);
          // console.log(dropResult);
          // console.log(collection);
          if ((dropResult.removedIndex == null && dropResult.addedIndex == null ) || (dropResult.removedIndex != null && dropResult.addedIndex != null)) {
              
          }else{
              if (dropResult.removedIndex != null) {
                  // fila donde se quito
                  this.RemovedElement = collection;
                  this.ColumnRemoved = groupIndex;
              }
              if (dropResult.addedIndex != null ) {
                  // fila donde se agrego
                  this.AddedElement = collection;
                  this.ColumnAdded = groupIndex;
              }
              if (this.RemovedElement != null && this.AddedElement != null) {
                  //s aqui agregar el guardado en firebase
                  console.log("aqui se agrego el elemento");
                  // console.log(this.RemovedElement);
                  // console.log(this.AddedElement);
  
                  if (this.RemovedElement == 'MainGroup' && this.AddedElement == 'groupsDragDrop'){
                    // console.log(this.classroom.Groups[this.groups[this.ColumnAdded].id]);
                    let ids =  Object.keys(this.classroom.Groups[this.groups[this.ColumnAdded].id].Students);
                    //Aqui controlamos para que no se agregue mas estudiantes que 5 o cuando el juego esta iniciado
                    //desde el contenedor de estudiantes 
                    if (ids.length>4 || this.groups[this.ColumnAdded].IdRoom!="") {
                      this.alertError = ids.length>4?"Groups cannot have more than 5 students":"Students cannot be added while the game is running.";
                      let principal = this;
                      setTimeout(function () { principal.alertError = '' }, 3000);
                      return;
                    }
                    var batch = db.batch();
                    var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
                    batch.update(group1Ref, {[`Groups.${this.groups[this.ColumnAdded].id}.Students.${[[dropResult.payload.id]]}`]: {}});
  
                    var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
                    batch.update(group1Ref, {[`MainGroup.${dropResult.payload.id}.Group`]: this.groups[this.ColumnAdded].id });
                  
                    batch.commit().then(() => {
                      console.log("Document successfully !");
                      this.RemovedElement = null;
                      this.AddedElement = null;
                      this.ColumnRemoved = '';
                      this.ColumnAdded = '';
                    })
                  }else if (this.RemovedElement == 'groupsDragDrop' && this.AddedElement == 'groupsDragDrop') {
                    // aplicamos en caso de que se mueva entre grupos
                    console.log("se movio entre grupos");
                    let ids =  Object.keys(this.classroom.Groups[this.groups[this.ColumnAdded].id].Students);
                    //Aqui controlamos para que no se agregue mas estudiantes que 5 o cuando el juego esta iniciado
                    //entre grupos
                    if (ids.length>4 || this.groups[this.ColumnAdded].IdRoom!="") {
                      this.alertError = ids.length>4?"Groups cannot have more than 5 students":"Students cannot be added while the game is running..";
                      let principal = this;
                      setTimeout(function () { principal.alertError = '' }, 3000);
                      return;
                    }
                    console.log(this.groups[this.ColumnAdded].id);
                    console.log(this.groups[this.ColumnRemoved].id);
                    var batch = db.batch();
                    var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
                    batch.update(group1Ref, {[`Groups.${this.groups[this.ColumnRemoved].id}.Students.${[[dropResult.payload.id]]}`]: firebase.firestore.FieldValue.delete()});
  
                    var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
                    batch.update(group1Ref, {[`Groups.${this.groups[this.ColumnAdded].id}.Students.${[[dropResult.payload.id]]}`]:  {}});
  
                    batch.commit().then(() => {
                      console.log("Document successfully !");
                      this.RemovedElement = null;
                      this.AddedElement = null;
                      this.ColumnRemoved = '';
                      this.ColumnAdded = '';
                    })
                  }else{
                    // aplicamos en caso de que se mueva de grupos a main
                    console.log("se regreso al main");
                    var batch = db.batch();
                    var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
                    batch.update(group1Ref, {[`Groups.${this.groups[this.ColumnRemoved].id}.Students.${[[dropResult.payload.id]]}`]: firebase.firestore.FieldValue.delete()});
  
                    var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
                    batch.update(group1Ref, {[`MainGroup.${dropResult.payload.id}.Group`]: "" });
  
                    var group2Ref = db.collection('Students').doc(dropResult.payload.id);
                    batch.update(group2Ref, { IdRoom: firebase.firestore.FieldValue.delete() });
                  
                    batch.commit().then(() => {
                      console.log("Document successfully !");
                      this.RemovedElement = null;
                      this.AddedElement = null;
                      this.ColumnRemoved = '';
                      this.ColumnAdded = '';
                    })
                  }
              }
              
              // let result= applyDrag(this[collection][groupIndex], dropResult);
              // this.$set(this[collection], groupIndex, result);
              // if (collection == 'MainGroup' && dropResult.removedIndex != null){
              //   // en este caso se agregan desde main group
              
              // }
          }
      },
      getChildPayloadMain( itemIndex) {
          // this.log("get-child-payload", groupIndex, itemIndex);
          return this.mainGroup[itemIndex];
      },
      getChildPayload(groupIndex, itemIndex) {
        this.log("get-child-payload", groupIndex, itemIndex);
        return this.groupsDragDrop[groupIndex][itemIndex];
      },
      addColumn() {
        this.groupsDragDrop.push(generate(this.groupsDragDrop.length + 1));
        this.flags.push({ drop: true, animate: true });
      },
  
      removeColumn() {
        this.groupsDragDrop.pop();
        this.flags.pop();
      },
  
      log(name, ...args) {
        if (this.logs[name]) {
          this.logPayload ? console.log(name, ...args) : console.log(name);
        }
      },
      ModalJuegos (){
        if (this.GroupSelected.length == 0) {
          this.alertError = "Select Groups";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
          return;     
        }
        
        this.dialogJuegos = true;
      },
      async IniciarJuegosGrupos(){
        this.dialogLoading = true;
        for (const indexGroup of this.GroupSelected) {
          this.dialogJuegos = false;
          this.esTiempoActivo = true;
          let segundos = parseInt(this.TiempoJuegoSeleccionado)*60;
          let StudentsGroup = [];
          this.groupsDragDrop[indexGroup].forEach(function(element,i) {
            if (typeof(element.LastLoginPlatform)==='undefined')
              StudentsGroup.push({Nickname: element.name, AvatarId:"1",IsConnected: false,Score:0, UserUID: element.id});
            else
              if (element.LastLoginPlatform.toLowerCase()!="webgl")
                StudentsGroup.push({Nickname: element.name, AvatarId:"1",IsConnected: false,Score:0, UserUID: element.id});
          });
          //if (StudentsGroup.length != 0) {
          if (StudentsGroup.length > 1) {//Nos aseguramos que haya mas de un estudiante por grupo
            const IniciarMultiplayerGame = functions.httpsCallable('IniciarMultiplayerGame');
            let respuesta = await IniciarMultiplayerGame({IdClass: this.$route.params.class, Group: this.groups[indexGroup].id, IdTeacher: this.user.UserUID, Game: this.juegoGrupalSeleccionado, Mission: 'Mision'+this.MultiplayerMisionSelected, IsStarted: true, MPStudents:StudentsGroup, time: segundos, SchoolReference:this.user.Reference.path });
            console.log(respuesta);
          }else{
            this.alertError = "Debe tener mas de un jugador para poder Iniciar el Juego.";
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
            break;
          }
        }
        this.GroupSelected=[];
        this.dialogLoading = false;
        return;
        ////////--------antigua version
        if (this.juegoGrupalSeleccionado == 0) {
          this.alertError = "necesita seleccionar juego";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
          return ;
        }
        if (this.groupsDragDrop.length == 0) {
          this.alertError = "necesita crear grupos";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
          return ;
        }
        this.dialogJuegos = false;
        this.esTiempoActivo = true;
        // crear room y pasar datos al room
        let segundos = parseInt(this.TiempoJuegoSeleccionado)*60;
        this.groupsDragDrop.forEach(grupoClase => {
          if (grupoClase.length>0){
            console.log(grupoClase);
            
            let StudentsGroup = [];
            grupoClase.forEach(function(element,i) {
              StudentsGroup.push({Nickname: element.name, AvatarId:"1",IsConnected: false,Score:0, UserUID: element.id});
            });
            const IniciarMultiplayerGame = functions.httpsCallable('IniciarMultiplayerGame')
              IniciarMultiplayerGame({IdClass: this.$route.params.class, Group: this.groups[i].id, IdTeacher: this.user.UserUID, Game: this.juegoGrupalSeleccionado, Mission: 'Mision'+this.MultiplayerMisionSelected, IsStarted: true, MPStudents:StudentsGroup, time: segundos, SchoolReference:this.user.Reference.path }).then((respues) => {
                console.log(respues)
            })
          }
         
        });
        
  
        // guardar auto id en alumno y  classroom 
        // empezar contador
        this.iniciarTemporizador();
      },
      async DeleteGameProgress(group){
        this.dialogLoading = true;
        // TODO: falta eliminar task
        console.log(group);
        let RoomData = await db.collection("MultiplayerGame").doc(group.IdRoom).get();
        let TeacherData =RoomData.data();
        console.log(TeacherData)
        
        await db.collection("SchoolTeacher").doc(TeacherData.TeacherId).collection("Classrooms").doc(TeacherData.Classrooms).update({
          [`Groups.${TeacherData.GroupId}.IdRoom`]: ""
        });
        for (const idStudent of TeacherData.MPStudents) {
          await db.collection("Students").doc(idStudent.UserUID).update({
            IdRoom: null
          })
        }
        const MPTaskGoogleDelete = functions.httpsCallable('MPTaskGoogleDelete')
        await MPTaskGoogleDelete({ Task: TeacherData.TaskName, idRoom:group.IdRoom });
        console.log("termino");
        this.dialogLoading = false;
        this.GroupSelected=[];
      },
      ConfirmRandom(){
        //verificar si tiene alumnos para reordenar
        for (const iterator of this.mainGroup) {
          if (iterator.group != "") {
            this.dialogConfirmRandom= true;
            return;
          }
        }
        //si esta vacio proceder
        this.RandomStudents();
      },
      async RandomStudents(){
        this.dialogLoading = true;
        let Groups = this.classroom.Groups;
        let idsGroups = Object.keys(Groups);
        for (const idGroup of idsGroups) {
          Groups[idGroup].Students = {};
        }
        let CurrentStudents = this.classroom.MainGroup;
        let idsStudents = Object.keys(CurrentStudents);
        for (const idStudent of idsStudents) {
          let idGroup =0;
          let ids =[];
          let IsActivo= true;
          do {
            idGroup=idsGroups[Math.floor(Math.random()*idsGroups.length)];
            ids= Object.keys(Groups[idGroup].Students);
            if (ids.length <5) {
              IsActivo = false;
            }
          } while (IsActivo );
          CurrentStudents[idStudent].Group = idGroup;
          //Groups[idGroup].Students[idStudent]={Name: CurrentStudents[idStudent].Name, LastName:CurrentStudents[idStudent].LastName};
          Groups[idGroup].Students[idStudent];
        }
        await db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).update({
          [`Groups`]: Groups,
          [`MainGroup`]: CurrentStudents,
        });
        this.dialogLoading = false;
        this.dialogConfirmRandom = false;
      },
      async RemoveStudentsGroups(){
        this.dialogLoading = true;
        let CurrentStudents = this.classroom.MainGroup;
        let idsStudents = Object.keys(CurrentStudents);
        for (const idStudent of idsStudents) {
          CurrentStudents[idStudent].Group = "";
        }
        console.log(CurrentStudents);
  
        let Groups = this.classroom.Groups;
        let idsGroups = Object.keys(Groups);
        for (const idGroup of idsGroups) {
          Groups[idGroup].Students = {};
        }
        await db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class).update({
          [`Groups`]: Groups,
          [`MainGroup`]: CurrentStudents,
        });
        this.dialogLoading = false;
         this.GroupSelected=[];
      },
      iniciarTemporizador(){
        console.log(this.TiempoJuegoSeleccionado)
        let minutos = this.TiempoJuegoSeleccionado;
        this.TiempoTemporizador = minutos;
        const milisegundos = (minutos * 60) * 1000;
        let fechaFuturo = new Date(new Date().getTime() + milisegundos);
        // clearInterval(idInterval);
        let me = this;
        setInterval(() => {
          const tiempoRestante = fechaFuturo.getTime() - new Date().getTime();
          if (tiempoRestante <= 0) {
            console.log("Tiempo terminado");
            me.esTiempoActivo = false;
            clearInterval();
          } else {
            // $tiempoRestante.textContent = milisegundosAMinutosYSegundos(tiempoRestante);
            me.TiempoTemporizador=me.milisegundosAMinutosYSegundos(tiempoRestante);
          }
        }, 1000);
      },
      milisegundosAMinutosYSegundos (milisegundos) {
        const minutos = parseInt(milisegundos / 1000 / 60);
        milisegundos -= minutos * 60 * 1000;
        let segundos = (milisegundos / 1000);
        return `${this.agregarCeroSiEsNecesario(minutos)}:${this.agregarCeroSiEsNecesario(segundos.toFixed(0))}`;
      },
      agregarCeroSiEsNecesario(valor){
        if (valor < 10) {
          return "0" + valor;
        } else {
          return "" + valor;
        }
      },
      ProgramarExamen (){
        
        // console.log(this.listTest[0].id);
        // db.collection("Students").doc(this.listTest[0].id).get().then((doc)=> {
        //   let datos = doc.data();
        //   if (Object.prototype.hasOwnProperty.call(datos, 'IdExam')) {
        //     const d = new Date();
        //     let month = d.getMonth()+1;
        //     if (month == datos.IdExam.split('-')[1]) {
        //       return;
        //     }
  
            
        //   }
        //   this.dialogTableTest = true;
        //   this.IsOptionGenerateTest = true;
        // });
        this.dialogTableTest = true;
        this.IsOptionGenerateTest = true;
      },
      ReporteExamen(){
        this.selectedTest = this.ListManageTest.slice();
        if (this.ExistTestCurrentMonth) {
          this.dialogConfirmGenerateTest = true
        }else{
          this.GenerateTest();
        }
  
        // this.dialogTableTest = true;
        // this.IsOptionReportTest = true;
      },
      async GenerateTest(){
        this.dialogLoading = true;
        let StudentSelected = this.selectedTest;
        // this.closeDialogTableTest();
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaExam= month+"-"+day+"-"+year;
        let studentsGenerate= [];
        for (const element of StudentSelected) {
          await db.collection("Students").doc(element.id).update({ IdExam:fechaExam });
          // await db.collection("Students").doc(element.id).collection("Exams").doc(fechaExam).set({ FechaCreacion: date }, { merge: true });
          studentsGenerate.push(element.id);
          // // verificamos si existe
          // let verifyExists = await db.collection("Students").doc(element.id).collection("Exams").doc(fechaExam).get();
          // if (verifyExists.exists) {
          //   await db.collection("Students").doc(element.id).collection("Exams").doc(fechaExam).delete();
          // }
        }
        await db.collection("SchoolTeacher").doc(this.user.UserUID).collection("Classrooms").doc(this.$route.params.class).set({ Tests: { [`${fechaExam}`] : {Estado:true,Students:studentsGenerate} } }, { merge: true });
        this.dialogLoading = false;
        // this.StepTest = 0;
        this.ListResultResportTest = [];
        this.ItemsDatesTests = [];
        this.ShowManageTest();
        this.dialogConfirmGenerateTest = false;
      },
      async RegenerateTestOneStudent(item){
        // this.selectedTest = []
        // this.selectedTest.push(item);
        let StudentSelected = []
        StudentSelected.push(item);
        // this.GenerateTest();
        this.dialogLoading = true;
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let fechaExam= month+"-"+day+"-"+year;
        let Crear = true;
        await db.collection("Students").doc(item.id).update({ IdExam:fechaExam });
        if (Object.prototype.hasOwnProperty.call(this.classroom, 'Tests')) {
          if (Object.prototype.hasOwnProperty.call(this.classroom.Tests, fechaExam)) {
            if (this.classroom.Tests[fechaExam].Estado) {
              if (this.classroom.Tests[fechaExam].Students.includes(item.id)) {
                // existe la fecha de examen  y esta programado el estudiante. No Sobreescribir
                // console.log(item.id);
                // console.log(fechaExam);
  
                // await db.collection("Students").doc(item.id).collection("Exams").doc(fechaExam).delete();
                Crear = false;
              }else {
                Crear = false;
                // existe la fecha de examen y no esta programado. Push to array
                await db.collection("SchoolTeacher").doc(this.user.UserUID).collection("Classrooms").doc(this.$route.params.class).update({ [`Tests.${fechaExam}.Students`]:  firebase.firestore.FieldValue.arrayUnion(item.id) })
              }
            }
          }
        }
        if (Crear)
          await db.collection("SchoolTeacher").doc(this.user.UserUID).collection("Classrooms").doc(this.$route.params.class).set({ Tests: { [`${fechaExam}`] : {Estado:true,Students:[item.id]} } }, { merge: true });
  
        this.dialogLoading = false;
        this.ShowManageTest();
      },
      async GenerateReport(){
        if (Object.prototype.hasOwnProperty.call(this.classroom, 'Tests')) {
          let ids = Object.keys(this.classroom.Tests);
              for (const iterator of ids) {
                console.log(iterator);
                console.log(this.classroom.Tests[iterator]);
              }
        }
        // console.log(this.classroom.Test);
        this.dialogLoading = true;
        this.StepTest = 0;
        this.ItemsDatesTests = [];
        let TestsSelected = [];
        // for (const student of this.selectedTest) {
        for (const student of this.ListManageTest) {
         
         if (Object.prototype.hasOwnProperty.call(this.classroom, 'Tests')) {
          let ids = Object.keys(this.classroom.Tests);
              for (const iterator of ids) {
                if (this.classroom.Tests[iterator]) {
                  let test = await db.collection("Students").doc(student.id).collection("Exams").doc(iterator).get();
                  if (test.exists) {
                    console.log(test.exists)
                    let valueIndex = -1;
                    let fecha = test.id;
                    for (let index = 0; index < TestsSelected.length; index++) {
                      if (TestsSelected[index].fecha == fecha) {
                        valueIndex = index;
                      }
                    }            
                    if (valueIndex == -1) {
                      TestsSelected.push({fecha: fecha, students: [ {name: student.name, value:test.data().EvaluacionGrupo} ]});
                    }else{
                      TestsSelected[valueIndex].students.push({name: student.name, value:test.data().EvaluacionGrupo});
                    }
                  }
                  
                }
              }
        }
        }
        // console.log(TestsSelected);
        this.ListResultResportTest =TestsSelected;
        this.WriteSelectDatesTest();
        // this.closeDialogTableTest();
        this.dialogLoading = false;
      },
      WriteSelectDatesTest(){
        this.ItemsDatesTests = [];
        // console.log(this.selectedTest.length == 1);
        if (this.selectedTest.length == 1){
          this.ItemsDatesTests.push({ fecha: "All Test of "+this.selectedTest[0].name, value: -1 , id:this.selectedTest[0].id});
        }
        for (let index = 0; index < this.ListResultResportTest.length; index++) {
          this.ItemsDatesTests.push({ fecha: this.ListResultResportTest[index].fecha, value: index });
        }
        if (this.ItemsDatesTests.length == 0) {
          this.alertError = "Does not have any test record";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
          // console.log("no hay alumnos en su clase");
        }
      },
      WriteTableTest(indiceTabla){ // DEPRECATED
        console.log(indiceTabla);
        this.ListFinalStudents = [];
        if (indiceTabla == -1) {
          this.TextoCabeceraTabla = "Test Date"
          console.log(this.ItemsDatesTests[0].id);
          db.collection("Students").doc(this.ItemsDatesTests[0].id).collection("Exams").get().then((contents) =>{
            if (contents.docs.length != 0) {
              let ListFinalStudents = []
              for (const test of contents.docs) { 
                // console.log(test.id);
                let Evaluaciones= test.data().EvaluacionGrupo;
                let StudentTest = [];
                // console.log(Evaluaciones);
                for (const nota in Evaluaciones) {
                  // console.log(nota);
                  let buenas = [];
                  let malas = [];
                  let unidad = Evaluaciones[nota].ListaUnidades;
                  unidad.forEach(element2 => {
                    if (element2.Acierto) 
                      buenas.push(element2.Escritura);
                    else
                      malas.push(element2.Escritura);
                  });
                  StudentTest.push({ buenas: buenas.length, malas: malas.length})
                }
                ListFinalStudents.push({name: test.id,StudentTest:StudentTest });
              }
              // console.log(ListFinalStudents);
              this.ListFinalStudents =ListFinalStudents;
            }
          });
          this.StepTest = 1;
          return        
        }
        this.TextoCabeceraTabla ="Students";
        // console.log(indiceTabla);
        let tableTest = document.getElementById("TablaReportTest")
        let ListFinalStudents = []
          let students = this.ListResultResportTest[indiceTabla].students;
          for (let indiceS = 0; indiceS < students.length; indiceS++) {//aqui recorre studiantes
            let calificacion =students[indiceS].value;
            let TextoUnidad = "";
            let StudentTest = [];
            for (const nota in calificacion) {
              
              const element = calificacion[nota];
              // console.log(element);
              let buenas = [];
              let malas = [];
              let unidad = element.ListaUnidades;
              unidad.forEach(element2 => {
                
                if (element2.Acierto) {
                  buenas.push(element2.Escritura);
                  // buenas += "<v-chip class='ma-1' color='green' text-color='white'><strong>"+element2.Escritura+"</strong></v-chip>";
                }else{
                  malas.push(element2.Escritura);
                  // malas += "<v-chip class='ma-1' color='red' text-color='white'><strong>"+element2.Escritura+"</strong></v-chip>";
                }
              });
              StudentTest.push({ buenas: buenas.length, malas: malas.length})
              // TextoUnidad += "<td>"+buenas+"</td><td>"+malas+"</td>";
            }
            ListFinalStudents.push({name: students[indiceS].name,StudentTest:StudentTest });
            
          }
          // console.log(ListFinalStudents);
          this.ListFinalStudents =ListFinalStudents;
          this.StepTest = 1;
        // }
        //  
        
      },
      closeDialogTableTest () {
        this.dialogTableTest = false
        this.IsOptionReportTest = false;
        this.IsOptionGenerateTest = false;
        this.selectedTest = [];
      },
      PrintReport(){
        window.html2canvas = html2canvas;
        var doc = new jsPDF('l', 'pt', 'letter');//jsPDF('l', 'mm', [297, 210]);
        doc.html(document.querySelector("#tablaImpresionTestIMP"), {
          callback: function(pdf) {
            pdf.save("reportTest.pdf");
          }
        });
        // var pdf = new jsPDF('l', 'pt', 'letter');
        // pdf.addHTML(document.querySelector("#tablaPrueba"), function () {
        //     pdf.save('Test.pdf');
        // });
      },
      async EliminarTest (){
        this.dialogConfirmDeleteTest = false;
        this.dialogLoading = true;
        console.log(this.classroom.MainGroup);
        let idsStudents = Object.keys(this.classroom.MainGroup);
        idsStudents.forEach(element => {
          db.collection("Students").doc(element).update({ IdExam:null });        
        });
        const date = new Date();
        let currentmonth = date.getMonth()+1;
  
        if (Object.prototype.hasOwnProperty.call(this.classroom, "Tests")) {   
          let idFechas = Object.keys(this.classroom.Tests);
          for (const element of idFechas) {
  
            if (this.classroom.Tests[element].Estado) {
              if (element.split('-')[0] == currentmonth) {
                await db.collection("SchoolTeacher").doc(this.user.UserUID).collection("Classrooms").doc(this.$route.params.class).update({[`Tests.${element}.Estado`]:false} );         
                console.log(this.classroom.Tests[element].Students);
                for (const studentClear of this.classroom.Tests[element].Students) {
                  await db.collection("Students").doc(studentClear).collection("Exams").doc(element).delete();
                }
              }  
            }
          };
        }
        this.dialogLoading = false;
        this.ShowManageTest();
      },
      // ConfigurarTest(){
      //   this.ExamenActivo.estado = false;
            
      //       if (Object.prototype.hasOwnProperty.call(this.classroom, 'Tests')) {
      //         const date = new Date();
      //         let month = date.getMonth() + 1;
      //         let ids = Object.keys(this.classroom.Tests);
      //         ids.forEach(element => {
      //           let partialDate= element;
      //           if (month == partialDate.split('-')[0]) {
      //             if (this.classroom.Tests[element].Estado) {
      //               this.ExamenActivo = {estado: true, date: element, students: this.classroom.Tests[element].Students};
      //             }
      //           }
      //         }) 
      //       }   
      // },
      async ShowManageTest(){var start = Date.now();
        this.dialogLoading = true;
        const date = new Date();
        let currentmonth = date.getMonth();
        this.TextCurrentMonth =this.MonthNames[currentmonth];
        let listaFromMain = [];
        let MapTestMonths = {}
        if (Object.prototype.hasOwnProperty.call(this.classroom, "Tests")) {
          let TestShortList = this.ShortMonthsStudents(this.classroom.Tests);
          //let idsTest = TestShortList;
          
          for (const fecha of TestShortList) {
            if (this.classroom.Tests[fecha].Estado) {
              let monthDate = fecha.split('-')[0] -1;
              let ListStudents =this.classroom.Tests[fecha].Students;
              for (const student of ListStudents) {
                if (Object.prototype.hasOwnProperty.call(MapTestMonths, student)) {
                  if (!Object.prototype.hasOwnProperty.call(MapTestMonths[student], this.MonthNames[monthDate])) {
                    /*let TestResult = await db.collection("Students").doc(student).collection("Exams").doc(fecha).get();
                    MapTestMonths[student][this.MonthNames[monthDate]] = {anio: fecha.split('-')[2], IsComplete: TestResult.exists, ShortName: this.MonthShortNames[monthDate]};*/
                    const TestResultFn = functions.httpsCallable('TestResult');
                    let TestResult = await TestResultFn({student: student, fecha: fecha});//console.log("--->",student, fecha, TestResult.exists);                    
                    MapTestMonths[student][this.MonthNames[monthDate]] = {anio: fecha.split('-')[2], IsComplete: TestResult.data.exists, ShortName: this.MonthShortNames[monthDate]};
                  }
                }else{
                  /*let TestResult = await db.collection("Students").doc(student).collection("Exams").doc(fecha).get();
                  MapTestMonths[student] = {[this.MonthNames[monthDate]]:{anio: fecha.split('-')[2], IsComplete: TestResult.exists, ShortName: this.MonthShortNames[monthDate]}};*/
                  const TestResultFn = functions.httpsCallable('TestResult');
                  let TestResult = await TestResultFn({student: student, fecha: fecha});//console.log("--->",student, fecha, TestResult.exists);                  
                  MapTestMonths[student] = {[this.MonthNames[monthDate]]:{anio: fecha.split('-')[2], IsComplete: TestResult.data.exists, ShortName: this.MonthShortNames[monthDate]}};
                }
              }
            }
          }
        }
        // console.log(MapTestMonths);
        let ids = Object.keys(this.classroom.MainGroup);
        ids.forEach(element => {
          let months= []
          let CurrentMonth = false;
          if (Object.prototype.hasOwnProperty.call(MapTestMonths, element)) {
            months = MapTestMonths[element];
            if (Object.prototype.hasOwnProperty.call(MapTestMonths[element], this.MonthNames[currentmonth])) {
              CurrentMonth = true;
              this.ExistTestCurrentMonth = true;
            }
          }
          listaFromMain.push({id: element,name: this.classroom.MainGroup[element].Name+' '+this.classroom.MainGroup[element].LastName, months: months, current:CurrentMonth});
        });
        listaFromMain.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

        this.ListManageTest= listaFromMain.slice();
        //lista de todos para reportes
        listaFromMain.unshift({id: -1, name: "All Students"});
        this.OptionsStudentsReport =listaFromMain;
        this.dialogLoading = false;var end = Date.now(); 
        console.log(end - start);
      },
      ShortMonthsStudents(ListaFechas){
        let idsTest = Object.keys(ListaFechas);
        idsTest.sort(function(a,b){
          return new Date(parseInt(a.split('-')[2]),parseInt(a.split('-')[0])-1,parseInt(a.split('-')[1])) - new Date(parseInt(b.split('-')[2]),parseInt(b.split('-')[0])-1,parseInt(b.split('-')[1]));
        });
        return idsTest;
      },
      cambioTabTest(){
        switch (this.TabTest) {
          case "tab-2":
            // this.GenerateReport();
            this.NewVersionReport();
            break;
        }
      },
      async NewVersionReport(){
        let ListStudents = {}
        let ListMonths = [];
        if (Object.prototype.hasOwnProperty.call(this.classroom, 'Tests')) {
          let ids = Object.keys(this.classroom.Tests);
              for (const iterator of ids) {
                if (this.classroom.Tests[iterator].Estado) {
                  let StudentTemp= this.classroom.Tests[iterator].Students;
                  let month=this.MonthNames[iterator.split('-')[0] -1];
                  // console.log(iterator.split('-')[2]);
                  StudentTemp.forEach(element => {
                    if (Object.prototype.hasOwnProperty.call(ListStudents, element)) {   
                      // existe el estudiante 
  
                      if (Object.prototype.hasOwnProperty.call(ListStudents[element], month)) { 
                        //tiene el mismo mes
                        // console.log(iterator.split('-')[1]);
                        // console.log(ListStudents[element][month]);
                        if (parseInt(ListStudents[element][month])< parseInt(iterator.split('-')[1])) {
                          ListStudents[element][month] = iterator.split('-')[1];
                        }
                        
                      }
                      else{
                        ListStudents[element][month]= iterator.split('-')[1];
                        if (!ListMonths.includes(month))
                          ListMonths.push(month+' '+iterator.split('-')[2]);
                      }
                    }else{
                      ListStudents[element] = { [month]: iterator.split('-')[1] };
                      if (!ListMonths.includes(month))
                        ListMonths.push(month+' '+iterator.split('-')[2]);
                    }
                  });
                }
              }
        }
        this.OptionsMonthsReport = ListMonths;
        this.ListStudentsOptionsReport = ListStudents
      },
      async GetDataStudentMonth(){
        // if (Object.keys(this.TestReportStudentSelected).length == 0) {
        //   return;
        // }
        if ( this.TestReportMonthSelected!= "" && (Object.keys(this.TestReportStudentSelected).length != 0 || this.TestReportStudentSelected.id != -1 )) {
          this.dialogLoading = true;
          let ListFinalStudents = [];
          if (this.TestReportStudentSelected.id == -1) {
            let indexMonth=this.MonthNames.indexOf(this.TestReportMonthSelected.split(' ')[0])+1;
            // console.log(indexMonth);
            let ids = Object.keys(this.ListStudentsOptionsReport);
            for (const idStudent of ids) {
              if (typeof(this.classroom.MainGroup[idStudent])==='undefined') continue;
              if (Object.prototype.hasOwnProperty.call(this.ListStudentsOptionsReport[idStudent], this.TestReportMonthSelected.split(' ')[0])) 
              {
                let fecha = indexMonth+'-'+this.ListStudentsOptionsReport[idStudent][this.TestReportMonthSelected.split(' ')[0]]+'-'+this.TestReportMonthSelected.split(' ')[1];   
                let TempStudent = await db.collection("Students").doc(idStudent).collection("Exams").doc(fecha).get();
                if (TempStudent.exists) {
                  // console.log(idStudent);
                  // console.log(fecha);
                  // console.log(TempStudent.data());
                  
                  let Evaluaciones= TempStudent.data().EvaluacionGrupo;
                  let StudentTest = [];
                  for (const nota in Evaluaciones) {
                    let buenas = [];
                    let malas = [];
                    let unidad = Evaluaciones[nota].ListaUnidades;
                    unidad.forEach(element2 => {
                      if (element2.Acierto) 
                        buenas.push(element2.Escritura);
                      else
                        malas.push(element2.Escritura);
                    });
                    StudentTest.push({ buenas: buenas.length, malas: malas.length})
                  }
                  ListFinalStudents.push({name: this.classroom.MainGroup[idStudent].Name.split(' ')[0] +' '+this.classroom.MainGroup[idStudent].LastName.split(' ')[0],StudentTest:StudentTest });              
                }else{
                  //console.log(idStudent);
                  //console.log(this.classroom.MainGroup[idStudent]);
                  ListFinalStudents.push({name: this.classroom.MainGroup[idStudent].Name.split(' ')[0] +' '+this.classroom.MainGroup[idStudent].LastName.split(' ')[0],StudentTest:[] });  
                }
  
              }
            }
          }else{
            let StudetnMonths = this.TestReportStudentSelected.months;
            console.log(StudetnMonths);
            let idsStudentsMonths = Object.keys(StudetnMonths);
            for (const month of idsStudentsMonths) {
            console.log(month);
  
              let mes = this.MonthNames.indexOf(month)+1;
              let fecha = mes+'-'+this.ListStudentsOptionsReport[this.TestReportStudentSelected.id][month]+'-'+StudetnMonths[month].anio;
              console.log(fecha);
              let TempStudent = await db.collection("Students").doc(this.TestReportStudentSelected.id).collection("Exams").doc(fecha).get();
              if (TempStudent.exists) {
            console.log("entro2");
  
                let Evaluaciones= TempStudent.data().EvaluacionGrupo;
                let StudentTest = [];
                for (const nota in Evaluaciones) {
            console.log("entr3");
  
                  let buenas = [];
                  let malas = [];
                  let unidad = Evaluaciones[nota].ListaUnidades;
                  unidad.forEach(element2 => {
                    if (element2.Acierto) 
                      buenas.push(element2.Escritura);
                    else
                      malas.push(element2.Escritura);
                  });
                  StudentTest.push({ buenas: buenas.length, malas: malas.length})
                }
                ListFinalStudents.push({name: month + " " +StudetnMonths[month].anio,StudentTest:StudentTest });              
              }
            }
            this.TestReportMonthSelected="";
          }
          this.ListFinalStudents =ListFinalStudents;
          this.dialogLoading = false;
        } else {
          if (this.TestReportMonthSelected == "") {
            console.log("falta seleccionar mes");
            this.$refs.monthsReport.focus()
            this.$refs.monthsReport.activateMenu()
          }
        }
      },
      async GenerateTestByStudent(student){
        console.log(student);
        this.TabTest = "tab-2";
        this.TestReportStudentSelected = student;
        this.TestReportMonthSelected = 'n';
        await this.NewVersionReport();
        this.GetDataStudentMonth();
      },
      ClickShowOptionsGroups(group, student){
        console.log(group);
        console.log(student);
        let ids =  Object.keys(this.classroom.Groups[group.id].Students);
        console.log(ids);
        if (ids.length>4) {
          this.alertError = "Groups cannot have more than 5 students";
          let principal = this;
          setTimeout(function () { principal.alertError = '' }, 3000);
          return;
        }
        var batch = db.batch();
        var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
        batch.update(group1Ref, {[`Groups.${group.id}.Students.${[[student.id]]}`]: {  Name: student.name, LastName:student.lastName }});
  
        var group1Ref = db.collection('SchoolTeacher').doc(this.user.UserUID).collection('Classrooms').doc(this.$route.params.class);
        batch.update(group1Ref, {[`MainGroup.${student.id}.Group`]: group.id });
                  
        batch.commit().then(() => {
          console.log("Document successfully !");
        })
      }
    },
    mounted() {
      // this.ManualTeacher();
    },
    computed: {
      formTitle () {
        return this.editedGroupUID === -1 ? 'New Group' : 'Edit Group'
      },
    },
    watch: {
      dialogNewGroup (val) {
        val || this.closeDialogNewGroup()
      },
      dialog (val) {
        val || this.closeDialogStudent()
      },
      dialogLoad (val) {
        if (!val) return
        // setTimeout(() => (this.dialogLoad = false), 4000)
      },
      dialogTableTest (val){
        val || this.closeDialogTableTest()
      }
    }
  }
  </script>
  <style>
    .title-classname{
      font-size: 1.5em;
    }
    #qr-code{
      width: 6.5in;
      padding: 15px;
    }
    #qr-code .col{
      height: 285px;
    }
    .texto-group-default{
      font-size: 13px;
      font-family: cursive;
      opacity: 0.6;
      text-align: center;
    }
    .estilos-tabla{
      width: 98%;
      border-collapse: collapse;
    }
    .estilos-tabla thead tr th{
      border: 1px solid #999;
    }
  
  
    
    .range-slider .input-range {
      -webkit-appearance: none;
      height: 1045px;
      
      transform: rotate(180deg);
      
      outline: none;
      -webkit-appearance: slider-vertical; /* WebKit */
    }
    .selectedCell {
      background-color: #0075ff !important;
      color: white;
    }
  
  input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: red;
      width: 20px;
      height: 20px;
  }
  
  .numeroMision{
    font-size: 10vw;
    margin-top: -25px;
    font-weight: 800;
    height: 100px;
    color: white;
  }
  @media only screen and (min-width: 1400px) {
    .numeroMision{
      margin-top: -50px;
      font-size: 9vw;
    }
  }
  .borderImage{
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 6px 6px 10px;
  }
  
  .tituloMision{
    border: 1px solid;
    text-align: center;
    padding: 5px;
    background-color: #6ab7bf;
    color: white;
    margin-top: 35px;
    font-size: 30px;
    border-radius: 15px;
    font-weight: 600;
  }
  .listaMision p{
    margin-bottom: 0px;
    font-size: 18px;
    margin: 0 10px 0 5px;
    padding-left: 35px;
    position: relative;
  }
  .listaMision .selected{
    border-radius: 15px;
    background-color: #6ab7bf;
    color: white;
  }
  .listaMision .selected::before{
    display: block;
    position: absolute;
    left: 0rem; 
    height: 30px;
    width: 30px;
    line-height: 30px; 
    padding-left: 10px;
    content: '>';
    background-color: white;
    color: black;
    border-radius: 50px;
  }
  .bloqueMision {
    background-color: #f1f2f5;
    margin-left: 15px;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.37) 6px 6px 10px;
    margin-bottom: 0px;
  }
  .tituloGrande{
    margin-top: 20px;
    font-size: 22px;
  }
  
  /* aqui empeizan estilos de drag drop */
  .groupsDragDrop {
    display: flex;
    justify-content: stretch;
  }
  
  .group {
    border: 2px solid rgb(0, 0, 0);
    flex: 1;
    padding: 5px;
    margin: 5px;
  }
  
  
  
  code[class*=language-], pre[class*=language-] {
      color: #ccc;
      background: none;
      font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
      font-size: 1em;
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      word-wrap: normal;
      line-height: 1.5;
      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;
      -webkit-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
  }
  pre[class*=language-] {
      padding: 1em;
      margin: .5em 0;
      overflow: auto;
  }
  
  .draggable-item {
      flex-direction: row!important;
      justify-content: space-between;
      text-align: center;
      background-color: #fff;
      border: 1px solid rgba(89, 123, 160, .667);
      border-left: 5px solid #42b883;
      margin: 3px;
      padding: 8px 0;
      cursor: pointer;
      border-radius: 4px;
  }
  
  .draggable-item-no {
      flex-direction: row!important;
      justify-content: space-between;
      text-align: center;
      background-color: #fff;
      border: 1px solid rgba(89, 123, 160, .667);
      border-left: 5px solid #FF0000;
      margin: 3px;
      padding: 8px 0;
      cursor: pointer;
      border-radius: 4px;
  }
  .draggable-item-horizontal {
      height: 300px;
      align-items: center;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 8px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      background-color: #fff;
      border: 1px solid rgba(89, 123, 160, .667);
      border-top: 5px solid #42b883;
      border-radius: 4px;
      transition: border-color .2s linear;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
  }
  /* .draggable-item-horizontal div, .draggable-item div {
      color: red;
  } */
  .dragging {
      background-color: #ff0;
  }
  .card-scene {
      max-width: 740px;
      margin: 0 auto;
      padding: 2rem 2.5rem;
  }
  .card-container {
      padding: 10px;
      margin: 5px;
      background-color: #f3f3f3;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
  }
  .card {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 8px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
      transition: border-color .2s linear;
  }
  .card-text, .card h3 {
      color: #f2f2f2;
  }
  .card h3 {
      margin: 0;
  }
  .card-column-header {
      font-size: 18px;
  }  
  .column-drag-handle {
      cursor: move;
      padding: 5px;
  }
  .card-ghost {
      transition: transform .18s ease;
      transform: rotate(5deg);
  }
  .card-ghost-drop {
      transition: transform .18s ease-in-out;
      transform: rotate(0deg);
  }
  .opacity-ghost {
      transition: all .18s ease;
      opacity: .8;
      background-color: #6495ed;
      box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .3);
  }
  .opacity-ghost-drop {
      opacity: 1;
      background-color: #fff;
      box-shadow: 3px 3px 10px 3px transparent;
  }
  .form-docs {
      display: flex;
  }
  .form {
      flex: 3;
      border: 1px solid rgba(0, 0, 0, .125);
      border-radius: 6px;
  }
  .form-fields-panel {
      flex: 1;
      margin-right: 50px;
  }
  .form-ghost {
      transition: .18s ease;
      box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, .08);
  }
  .form-ghost-drop {
      box-shadow: 0 0 2px 5px transparent;
  }
  .drop-preview {
      margin: 5px;
  }
  .cards-drop-preview, .drop-preview {
      background-color: rgba(150, 150, 200, .1);
      border: 1px dashed #abc;
  }
  .cards-drop-preview {
      margin: 5px 45px 5px 5px;
  }
  .form-field {
      height: 50px;
      width: 250px;
      line-height: 30px;
      vertical-align: middle;
      padding: 10px;
      background-color: #fff;
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      border-color: #fff #eee #ddd;
      border-style: solid;
      border-width: 1px;
      cursor: move;
  }
  .form-line {
      border: 1px solid #bcdae8;
      padding: 20px 30px;
      border-radius: 6px;
      transition: all .3s ease;
      transition-property: border-color, background-color;
      cursor: move;
  }
  .form-line, .form-line.selected {
      background-color: #f8f9fa;
  }
  #nprogress {
      pointer-events: none;
  }
  #nprogress .bar {
      background: #3eaf7c;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
  }
  #nprogress .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #3eaf7c, 0 0 5px #3eaf7c;
      opacity: 1;
      transform: rotate(3deg) translateY(-4px);
  }
  #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
  }
  #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border-color: #3eaf7c transparent transparent #3eaf7c;
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;
      -webkit-animation: nprogress-spinner .4s linear infinite;
      animation: nprogress-spinner .4s linear infinite;
  }
  .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
  }
  .nprogress-custom-parent #nprogress .bar, .nprogress-custom-parent #nprogress .spinner {
      position: absolute;
  }
  @-webkit-keyframes nprogress-spinner {
      0% {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(1turn);
  }
  }@keyframes nprogress-spinner {
      0% {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(1turn);
  }
  }html {
      scroll-behavior: smooth;
  }
  .icon.outbound {
      color: #aaa;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
  }
  .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
  }
  .page-edit {
      max-width: 740px;
      margin: 0 auto;
      padding: 2rem 2.5rem;
  }
  @media (max-width:959px) {
      .page-edit {
      padding: 2rem;
  }
  }@media (max-width:419px) {
      .page-edit {
      padding: 1.5rem;
  }
  }.page-edit {
      padding-top: 1rem;
      padding-bottom: 1rem;
      overflow: auto;
  }
  .page-edit .edit-link {
      display: inline-block;
  }
  .page-edit .edit-link a {
      color: #4e6e8e;
      margin-right: .25rem;
  }
  .page-edit .last-updated {
      float: right;
      font-size: .9em;
  }
  .page-edit .last-updated .prefix {
      font-weight: 500;
      color: #4e6e8e;
  }
  .page-edit .last-updated .time {
      font-weight: 400;
      color: #767676;
  }
  @media (max-width:719px) {
      .page-edit .edit-link {
      margin-bottom: .5rem;
  }
  .page-edit .last-updated {
      font-size: .8em;
      float: none;
      text-align: left;
  }
  }.page-nav {
      max-width: 740px;
      margin: 0 auto;
      padding: 2rem 2.5rem;
  }
  @media (max-width:959px) {
      .page-nav {
      padding: 2rem;
  }
  }@media (max-width:419px) {
      .page-nav {
      padding: 1.5rem;
  }
  }.page-nav {
      padding-top: 1rem;
      padding-bottom: 0;
  }
  .page-nav .inner {
      min-height: 2rem;
      margin-top: 0;
      border-top: 1px solid #eaecef;
      padding-top: 1rem;
      overflow: auto;
  }
  .page-nav .next {
      float: right;
  }
  .page {
      padding-bottom: 2rem;
      display: block;
  }
  .sidebar-group .sidebar-group {
      padding-left: .5em;
  }
  .sidebar-group:not(.collapsable) .sidebar-heading:not(.clickable) {
      cursor: auto;
      color: inherit;
  }
  .sidebar-group.is-sub-group {
      padding-left: 0;
  }
  .sidebar-group.is-sub-group>.sidebar-heading {
      font-size: .95em;
      line-height: 1.4;
      font-weight: 400;
      padding-left: 2rem;
  }
  .sidebar-group.is-sub-group>.sidebar-heading:not(.clickable) {
      opacity: .5;
  }
  .sidebar-group.is-sub-group>.sidebar-group-items {
      padding-left: 1rem;
  }
  .sidebar-group.is-sub-group>.sidebar-group-items>li>.sidebar-link {
      font-size: .95em;
      border-left: none;
  }
  .sidebar-group.depth-2>.sidebar-heading {
      border-left: none;
  }
  .sidebar-heading {
      color: #2c3e50;
      transition: color .15s ease;
      cursor: pointer;
      font-size: 1.1em;
      font-weight: 700;
      padding: .35rem 1.5rem .35rem 1.25rem;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      border-left: .25rem solid transparent;
  }
  .sidebar-heading.open, .sidebar-heading:hover {
      color: inherit;
  }
  .sidebar-heading .arrow {
      position: relative;
      top: -.12em;
      left: .5em;
  }
  .sidebar-heading.clickable.active {
      font-weight: 600;
      color: #3eaf7c;
      border-left-color: #3eaf7c;
  }
  .sidebar-heading.clickable:hover {
      color: #3eaf7c;
  }
  .sidebar-group-items {
      transition: height .1s ease-out;
      font-size: .95em;
      overflow: hidden;
  }
  .sidebar .sidebar-sub-headers {
      padding-left: 1rem;
      font-size: .95em;
  }
  a.sidebar-link {
      font-size: 1em;
      font-weight: 400;
      display: inline-block;
      color: #2c3e50;
      border-left: .25rem solid transparent;
      padding: .35rem 1rem .35rem 1.25rem;
      line-height: 1.4;
      width: 100%;
      box-sizing: border-box;
  }
  a.sidebar-link:hover {
      color: #3eaf7c;
  }
  a.sidebar-link.active {
      font-weight: 600;
      color: #3eaf7c;
      border-left-color: #3eaf7c;
  }
  .sidebar-group a.sidebar-link {
      padding-left: 2rem;
  }
  .sidebar-sub-headers a.sidebar-link {
      padding-top: .25rem;
      padding-bottom: .25rem;
      border-left: none;
  }
  .sidebar-sub-headers a.sidebar-link.active {
      font-weight: 500;
  }
  .sidebar ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
  }
  .sidebar a {
      display: inline-block;
  }
  .sidebar .nav-links {
      display: none;
      border-bottom: 1px solid #eaecef;
      padding: .5rem 0 .75rem;
  }
  .sidebar .nav-links a {
      font-weight: 600;
  }
  .sidebar .nav-links .nav-item, .sidebar .nav-links .repo-link {
      display: block;
      line-height: 1.25rem;
      font-size: 1.1em;
      padding: .5rem 0 .5rem 1.5rem;
  }
  .sidebar>.sidebar-links {
      padding: 1.5rem 0;
  }
  .sidebar>.sidebar-links>li>a.sidebar-link {
      font-size: 1.1em;
      line-height: 1.7;
      font-weight: 700;
  }
  .sidebar>.sidebar-links>li:not(:first-child) {
      margin-top: .75rem;
  }
  @media (max-width:719px) {
      .sidebar .nav-links {
      display: block;
  }
  .sidebar .nav-links .dropdown-wrapper .nav-dropdown .dropdown-item a.router-link-active:after {
      top: calc(1rem - 2px);
  }
  .sidebar>.sidebar-links {
      padding: 1rem 0;
  }
  }.kanban[data-v-2fcb2b94] {
      justify-content: stretch;
      width: 100%;
      height: 100%}
  .kanban[data-v-2fcb2b94], .kanban__move-icon[data-v-2fcb2b94] {
      display: flex;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  .kanban__move-icon[data-v-2fcb2b94] {
      align-items: center;
      justify-content: center;
      padding: 1rem;
      font-size: .9rem;
      color: #b0b0b0;
  }
  .kanban__move-icon span[data-v-2fcb2b94] {
      margin-left: 10px;
  }
  .kanban__move-icon svg[data-v-2fcb2b94] {
      width: 20px;
      height: 20px;
  }
  .kanban__drop-preview[data-v-2fcb2b94] {
      position: relative;
      margin: 10px;
      background-color: #edf1f3;
      border: 2px dashed rgba(0, 0, 0, .059);
      border-radius: 4px;
  }
  .kanban__drop-preview[data-v-2fcb2b94]:before {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #b0b0b0;
      transform: translate(-50%, -50%);
  }
  .kanban__drop-preview[data-v-2fcb2b94]:after {
      position: absolute;
      top: 50%;
      left: 27%;
      width: 20px;
      height: 20px;
      content: "";
      transform: translate(-50%, -50%);
  }
  .kanban__description[data-v-2fcb2b94], .kanban__icon[data-v-2fcb2b94] {
      display: flex;
      align-items: center;
  }
  .kanban__icon[data-v-2fcb2b94] {
      justify-content: center;
      background-color: #edf5f7;
      padding: .5rem;
      border-radius: 8px;
  }
  .kanban__title[data-v-2fcb2b94] {
      margin: 0 0 0 5px;
      font-size: 1rem;
      font-weight: 700;
      color: #555;
  }
  .kanban__quantity[data-v-2fcb2b94] {
      margin-top: 0;
      font-size: small;
      color: #b0b0b0;
      text-align: right;
  }
  .kanban__quantity--inner-count[data-v-2fcb2b94] {
      margin: 1rem 1rem 0;
      padding-top: .5rem;
  }
  .kanban__column[data-v-2fcb2b94] {
      flex: 1;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding: 10px;
      border-radius: 6px;
      transition: height .5s ease;
      border: 1px solid #e0e0e0;
  }
  .kanban__column[data-v-2fcb2b94]:not(:first-of-type) {
      margin-left: 15px;
  }
  .kanban__column--no-border[data-v-2fcb2b94] {
      border: none;
  }
  .kanban__header[data-v-2fcb2b94] {
      margin-bottom: 15px;
      background-color: #fff;
  }
  .kanban__content[data-v-2fcb2b94] {
      position: relative;
  }
  .kanban__skeleton[data-v-2fcb2b94] {
      display: flex;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-right: 15px;
      background-color: #f8f8f8;
      border-radius: 4px;
      overflow: auto;
  }
  .kanban__skeleton-container[data-v-2fcb2b94] {
      width: 100%}
  .kanban__skeleton-column[data-v-2fcb2b94] {
      flex: 1;
      height: calc(100% - 30px);
      padding: 10px;
      margin-left: 15px;
      background-color: #e9e9e9;
      border-radius: 6px;
  }
  .kanban__skeleton-card[data-v-2fcb2b94] {
      position: relative;
      height: 100px;
      padding: 15px;
      margin-top: 16px;
      background-color: #f5f5f5;
      border-radius: 6px;
  }
  .kanban__skeleton-card--one[data-v-2fcb2b94] {
      position: absolute;
      top: 8px;
  }
  .kanban__skeleton-card--two[data-v-2fcb2b94] {
      position: absolute;
      top: 8px;
      right: 10px;
  }
  .kanban__skeleton-card--three[data-v-2fcb2b94] {
      position: absolute;
      top: 30px;
  }
  .kanban__skeleton-card--four[data-v-2fcb2b94] {
      position: absolute;
      bottom: 2rem;
  }
  .kanban__skeleton-card--five[data-v-2fcb2b94] {
      position: absolute;
      left: 1rem;
      bottom: .5rem;
  }
  .draggable-item-horizontal[data-v-2fcb2b94], .draggable-item[data-v-2fcb2b94], .item[data-v-2fcb2b94] {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 8px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: #fff;
      border: 1px solid transparent;
      border-left: 5px solid #e0e0e0;
      border-radius: 4px;
      transition: border-color .2s linear;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
  }
  .item .card[data-v-2fcb2b94] {
      display: flex;
      flex-direction: column;
      gap: 4px;
  }
  .item .card small[data-v-2fcb2b94] {
      color: #838383;
  }
  .item.open[data-v-2fcb2b94] {
      border-left: 5px solid #f3c1c1;
  }
  .item.executing[data-v-2fcb2b94] {
      border-left: 5px solid #c4ebaf;
  }
  .item.finished[data-v-2fcb2b94] {
      border-left: 5px solid #b3d5e9;
  }
  .item.paralised[data-v-2fcb2b94] {
      border-left: 5px solid #dc3545;
  }
  .item.review[data-v-2fcb2b94] {
      border-left: 5px solid #e0e0e0;
  }
  .badge[data-v-2fcb2b94] {
      margin: 1rem 0;
      cursor: pointer;
  }
  .label[data-v-2fcb2b94] {
      color: #838383;
  }
  .kanban-action[data-v-2fcb2b94] {
      display: flex;
      justify-content: space-between;
  }
  .kanban-button[data-v-2fcb2b94] {
      max-width: 130px;
      pointer-events: none;
      border: none;
      color: #f7f7f7;
      padding: 1px 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 100px;
  }
  .kanban-button.open[data-v-2fcb2b94] {
      background-color: #f3c1c1;
  }
  .kanban-button.executing[data-v-2fcb2b94] {
      background-color: #c4ebaf;
  }
  .kanban-button.finished[data-v-2fcb2b94] {
      background-color: #b3d5e9;
  }
  .kanban-button.paralised[data-v-2fcb2b94] {
      background-color: #dc3545;
  }
  .kanban-button.review[data-v-2fcb2b94] {
      background-color: #e0e0e0;
  }
  .kanban[data-v-49ec39b7] {
      justify-content: stretch;
      width: 100%;
      height: 100%}
  .kanban[data-v-49ec39b7], .kanban__move-icon[data-v-49ec39b7] {
      display: flex;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  .kanban__move-icon[data-v-49ec39b7] {
      align-items: center;
      justify-content: center;
      padding: 1rem;
      font-size: .9rem;
      color: #b0b0b0;
  }
  .kanban__move-icon span[data-v-49ec39b7] {
      margin-left: 10px;
  }
  .kanban__move-icon svg[data-v-49ec39b7] {
      width: 20px;
      height: 20px;
  }
  .kanban__drop-preview[data-v-49ec39b7] {
      position: relative;
      margin: 10px;
      background-color: #edf1f3;
      border: 2px dashed rgba(0, 0, 0, .059);
      border-radius: 4px;
  }
  .kanban__drop-preview[data-v-49ec39b7]:before {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #b0b0b0;
      transform: translate(-50%, -50%);
  }
  .kanban__drop-preview[data-v-49ec39b7]:after {
      position: absolute;
      top: 50%;
      left: 27%;
      width: 20px;
      height: 20px;
      content: "";
      transform: translate(-50%, -50%);
  }
  .kanban__description[data-v-49ec39b7], .kanban__icon[data-v-49ec39b7] {
      display: flex;
      align-items: center;
  }
  .kanban__icon[data-v-49ec39b7] {
      justify-content: center;
      background-color: #edf5f7;
      padding: .5rem;
      border-radius: 8px;
  }
  .kanban__title[data-v-49ec39b7] {
      margin: 0 0 0 5px;
      font-size: 1rem;
      font-weight: 700;
      color: #555;
  }
  .kanban__quantity[data-v-49ec39b7] {
      margin-top: 0;
      font-size: small;
      color: #b0b0b0;
      text-align: right;
  }
  .kanban__quantity--inner-count[data-v-49ec39b7] {
      margin: 1rem 1rem 0;
      padding-top: .5rem;
  }
  .kanban__column[data-v-49ec39b7] {
      flex: 1;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding: 10px;
      border-radius: 6px;
      transition: height .5s ease;
      border: 1px solid #e0e0e0;
  }
  .kanban__column[data-v-49ec39b7]:not(:first-of-type) {
      margin-left: 15px;
  }
  .kanban__column--no-border[data-v-49ec39b7] {
      border: none;
  }
  .kanban__header[data-v-49ec39b7] {
      margin-bottom: 15px;
      background-color: #fff;
  }
  .kanban__content[data-v-49ec39b7] {
      position: relative;
  }
  .kanban__skeleton[data-v-49ec39b7] {
      display: flex;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-right: 15px;
      background-color: #f8f8f8;
      border-radius: 4px;
      overflow: auto;
  }
  .kanban__skeleton-container[data-v-49ec39b7] {
      width: 100%}
  .kanban__skeleton-column[data-v-49ec39b7] {
      flex: 1;
      height: calc(100% - 30px);
      padding: 10px;
      margin-left: 15px;
      background-color: #e9e9e9;
      border-radius: 6px;
  }
  .kanban__skeleton-card[data-v-49ec39b7] {
      position: relative;
      height: 100px;
      padding: 15px;
      margin-top: 16px;
      background-color: #f5f5f5;
      border-radius: 6px;
  }
  .kanban__skeleton-card--one[data-v-49ec39b7] {
      position: absolute;
      top: 8px;
  }
  .kanban__skeleton-card--two[data-v-49ec39b7] {
      position: absolute;
      top: 8px;
      right: 10px;
  }
  .kanban__skeleton-card--three[data-v-49ec39b7] {
      position: absolute;
      top: 30px;
  }
  .kanban__skeleton-card--four[data-v-49ec39b7] {
      position: absolute;
      bottom: 2rem;
  }
  .kanban__skeleton-card--five[data-v-49ec39b7] {
      position: absolute;
      left: 1rem;
      bottom: .5rem;
  }
  .draggable-item-horizontal[data-v-49ec39b7], .draggable-item[data-v-49ec39b7], .item[data-v-49ec39b7] {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 8px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: #fff;
      border: 1px solid transparent;
      border-left: 5px solid #e0e0e0;
      border-radius: 4px;
      transition: border-color .2s linear;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
  }
  .item .card[data-v-49ec39b7] {
      display: flex;
      flex-direction: column;
      gap: 4px;
  }
  .item .card small[data-v-49ec39b7] {
      color: #838383;
  }
  .item.open[data-v-49ec39b7] {
      border-left: 5px solid #f3c1c1;
  }
  .item.executing[data-v-49ec39b7] {
      border-left: 5px solid #c4ebaf;
  }
  .item.finished[data-v-49ec39b7] {
      border-left: 5px solid #b3d5e9;
  }
  .item.paralised[data-v-49ec39b7] {
      border-left: 5px solid #dc3545;
  }
  .item.review[data-v-49ec39b7] {
      border-left: 5px solid #e0e0e0;
  }
  .badge[data-v-49ec39b7] {
      margin: 1rem 0;
      cursor: pointer;
  }
  .label[data-v-49ec39b7] {
      color: #838383;
      margin-bottom: 5px;
  }
  .kanban-action[data-v-49ec39b7] {
      display: flex;
      justify-content: space-between;
  }
  .kanban-button[data-v-49ec39b7] {
      max-width: 130px;
      pointer-events: none;
      border: none;
      color: #f7f7f7;
      padding: 1px 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 100px;
  }
  .kanban-button.open[data-v-49ec39b7] {
      background-color: #f3c1c1;
  }
  .kanban-button.executing[data-v-49ec39b7] {
      background-color: #c4ebaf;
  }
  .kanban-button.finished[data-v-49ec39b7] {
      background-color: #b3d5e9;
  }
  .kanban-button.paralised[data-v-49ec39b7] {
      background-color: #dc3545;
  }
  .kanban-button.review[data-v-49ec39b7] {
      background-color: #e0e0e0;
  }
  .kanban-cards[data-v-49ec39b7] {
      display: flex;
      justify-content: center;
      margin: 5px 0;
  }
  .copy {
      display: flex;
      gap: 1rem;
  }
  .item {
      flex: 1;
  }
  .limited-height[data-v-576f461c] {
      height: 60vh;
      overflow: auto;
  }
  .controls[data-v-aca1d1d4] {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
  }
  .controls .title[data-v-aca1d1d4] {
      align-self: flex-start;
      margin: 0 1rem;
  }
  .controls .buttons[data-v-aca1d1d4] {
      align-self: flex-end;
      margin: 1rem;
  }
  .controls .actions[data-v-aca1d1d4] {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 1rem;
  }
  .controls>div[data-v-aca1d1d4] {
      padding-top: 1em;
  }
  .buttons[data-v-aca1d1d4], .column-actions[data-v-aca1d1d4] {
      display: flex;
      gap: 1rem;
  }
  .column-actions[data-v-aca1d1d4] {
      justify-content: space-evenly;
  }
  
  .flex-center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* header clasee */
  #tablaExportar  thead{
    background-color: rgb(217, 217, 217);
  }
  .dndrop-container{
    min-height: 65px;
  }
  
  /* tabla del reporte test */
  .column-mision1 {
    background-color: rgba(216, 213, 195, 0.568);
  }
  .column-mision2 {
    background-color: rgb(246, 255, 227, 0.568);
  }
  .column-mision3 {
    background-color: rgb(195, 195, 195, 0.568);
  }
  .column-mision4 {
    background-color: rgb(217, 255, 238, 0.568);
  }
  .column-mision5 {
    background-color: rgb(193, 222, 221, 0.568);
  }
  .column-mision6 {
    background-color: rgb(206, 226, 255, 0.568);
  }
  .column-mision6 {
    background-color: rgb(157, 198, 255, 0.568);
  }
  .column-mision7 {
    background-color: rgb(252, 231, 255, 0.568);
  }
  .column-final {
    background-color: rgba(34, 48, 250, 0.27);
  }
  .column-header {
    padding: 2% 1% 2% 1%;
    width: 11%;
    font-size: 12px;
    font-weight: bold;
  }
  .row-main {
    text-align: left;
    padding-left: 2px;
    height: 16px !important;
    font-weight: bold;
  }
  .tReportTest{
    font-weight: bold; 
    padding: 0 !important; 
    width: 20px !important;
  }
  .tablaReporteTest {
    border-collapse: collapse;
    width: 100%;
    margin: 5px;
  }
  .tablaReporteTest tr {
    border: 1px solid #babeb3;
    text-align: center;
  }
  .tablaReporteTest tr td {
    border-left: 1px solid #c7c9c5;
    border-right: 1px solid #c7c9c5;
  }
  .tablaReporteTest tr th {
    border-left: 1px solid #c7c9c5;
  }
  .margin-test-chip{
    margin: 1px;
    font-weight: 800;
    padding: 8px;
    height: 23px !important;
  }
  .margin-test-chip-print{
    margin: 2px;
    font-weight: 500;
    font-size: 10px !important;
    padding: 7px !important;
    height: 17px !important;
  }
  
  .selected-radio{
    background-color: rgb(193 255 210);
    border-radius: 3px;
    border: solid 0.5px #72ea93;
    
  
  }
  .position-cbmp{
    padding-left: 10px;
    width: 50px;
    margin: auto;
  }
  
  .row-multiplayer-table{
    opacity: 0.6;
    border: 1px solid #058179;
    border-radius: 10px;
    margin: 0 10px;
    background: rgb(41,204,183);
    background: linear-gradient(90deg, rgb(59, 186, 169) 0%, rgb(59, 186, 169) 30%, rgba(255,255,255,1) 50%);
  
  }
  .ResaltarFila {
    opacity: 1 !important;
  }
  /* .game1-mp{
    background-image: url('../../../assets/admin/Game1MP.png');
    background-repeat: no-repeat;
    background-size: 95%;
    background-position: center;
    height: 75px;
    border-radius: 25px 25px 0 0;
  } */
  .game1-mp{
    text-align: center;
    /* background-image: linear-gradient(to bottom, #ffffff 0%, #036866 50%, #ffffff 100%); */
    /* background-image: radial-gradient(circle, #be4848 0%, #ffffff 50%); */
  }
  .game1-mp img{
    width: 90%;
    opacity: 0.4;
    border-radius: 25px 25px 0 0;
  }
  .game1-mp p{
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 600;
    color: #656cff;
  }
  .RowColumnGameSelected{
    opacity: 1;
    background: linear-gradient(90deg, rgb(0, 132, 114) 0%, rgb(17, 161, 141) 30%, rgba(255,255,255,1) 50%);
  }
  
  .texto-fondo{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 50%;
    text-align: center;
  }
  </style>
  